export const styles: { [key: string]: React.CSSProperties } = {
  boxStyle: {
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%'
  },
  title: {
    marginTop: 0,
    marginBottom: '0px'
  },
  subtitle: {
    marginTop: 0,
    marginBottom: '35px'
  },
  icon: {
    fontSize: 16
  },
  cardsContainer: {
    maxWidth: '600px',
    margin: 'auto'
  },
  card: {
    cursor: 'default',
    width: '250px',
    height: '150px'
  }
};

export default styles;