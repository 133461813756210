interface URLQueryParams {
  [key: string]: string | number;
}

export const buildPath = (path: PATHS, params?: URLQueryParams) =>
  (params && Object.keys(params).reduce((acc, key) => acc.replace(`:${key}`, params[key].toString()), path)) || path;

export enum PATHS {
  ROOT = '/',
  LOGIN = '/login',
  HOME = '/home',
  GOALS = '/goals',
  RESOURCES = '/resources',
  NEW_RESOURCE = '/resources/new',
  RESOURCE = '/resources/:resourceId',
  CUSTOMERS = '/customers',
  NEW_CUSTOMER = '/customers/new',
  CUSTOMER = '/customers/:customerId',
  NEW_CONTACT = '/customers/:customerId/contact/new',
  CONTACT = '/customers/:customerId/contact/:contactId',
  SESSIONS = '/sessions',
  NEW_SESSION = '/sessions/new',
  SESSION = '/sessions/:sessionId',
}