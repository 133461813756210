import { API } from "aws-amplify";
import config from "../config";

const API_NAME = config.apiGateway[0].name;

export const getContacts = () => {
  const path = 'contacts';
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const getContact = (contactId: number) => {
  const path = `contacts/${contactId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const addContact = (contact: Omit<Contact, 'id'>) => {
  const path = 'contacts';
  const myInit = {
    body: contact,
    headers: {},
    response: false,
  };

  return API.put(API_NAME, path, myInit);
}

export const updateContact = ({ id, ...contact }: Contact) => {
  const path = `contacts/${id}`;
  const myInit = {
    body: contact,
    headers: {},
    response: false,
  };

  return API.post(API_NAME, path, myInit);
}

export const deleteContact = (contactId: number) => {
  const path = `contacts/${contactId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.del(API_NAME, path, myInit);
}

