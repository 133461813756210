import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { getUserInfo } from '../../api/user'

export interface UserState {
  data: User | undefined;
  status: Status;
  error: string | null | undefined;
}

const initialState: UserState = {
  data: undefined,
  status: 'idle',
  error: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})

const selectUserData = (state: RootState) => state.user.data
const selectUserStatus = (state: RootState) => state.user.status
const selectUserError = (state: RootState) => state.user.error

export const selectors = {
  selectUserData,
  selectUserStatus,
  selectUserError,
}

const fetchUserInfo = createAsyncThunk('getUserInfo', async () => await getUserInfo())

export const actions = {
  fetchUserInfo,
}

export default userSlice.reducer