import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./notFound";
import Root from "./root";
import Login from "./Login";
import Home from "./Home";
import Goals from "./Goals";
import Resources from "./Resources";
import { NewResourceContainer, UpdateResourceContainer } from "./Resource";
import Customers from "./Customers";
import { NewCustomerContainer, UpdateCustomerContainer } from "./Customer";
import { NewContactContainer, UpdateContactContainer } from "./Contact";
import Sessions from "./Sessions";
import { NewSessionContainer, UpdateSessionContainer } from "./Session";
import { PATHS } from "./paths";

const publicRoutes = (isValid: boolean, isSessionValid: () => void) => [
  {
    path: PATHS.LOGIN,
    element: <Login isSessionValid={isSessionValid} />,
    errorElement: <ErrorPage />,
  },
  ...(isValid ? [] : [{ path: "*", element: <Navigate to={PATHS.LOGIN} replace /> }]),
];

const privateRoutes = (isSessionValid: () => void) => [
  {
    path: PATHS.ROOT,
    element: <Root isSessionValid={isSessionValid} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: PATHS.HOME,
        element: <Home />,
      },
      {
        path: PATHS.GOALS,
        element: <Goals />,
      },
      {
        path: PATHS.RESOURCES,
        element: <Resources />,
      },
      {
        path: PATHS.NEW_RESOURCE,
        element: <NewResourceContainer />,
      },
      {
        path: PATHS.RESOURCE,
        element: <UpdateResourceContainer />,
      },
      {
        path: PATHS.CUSTOMERS,
        element: <Customers />,
      },
      {
        path: PATHS.NEW_CUSTOMER,
        element: <NewCustomerContainer />,
      },
      {
        path: PATHS.CUSTOMER,
        element: <UpdateCustomerContainer />,
      },
      {
        path: PATHS.NEW_CONTACT,
        element: <NewContactContainer />,
      },
      {
        path: PATHS.CONTACT,
        element: <UpdateContactContainer />,
      },
      {
        path: PATHS.SESSIONS,
        element: <Sessions />,
      },
      {
        path: PATHS.NEW_SESSION,
        element: <NewSessionContainer />,
      },
      {
        path: PATHS.SESSION,
        element: <UpdateSessionContainer />,
      },
      {
        path: PATHS.ROOT,
        element: <Navigate to={PATHS.HOME} replace />
      }
    ],
  }
];

const getRouter = (isSessionValid: () => void, isValid: boolean) => createBrowserRouter([
  ...(isValid ? privateRoutes(isSessionValid) : []),
  ...publicRoutes(isValid, isSessionValid),
]);

export default getRouter;