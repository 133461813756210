import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user'
import areaReducer from './area'
import goalReducer from './goal'
import resourceSlice from './resource'
import customerSlice from './customer'
import contactSlice from './contact'
import sessionSlice from './session'
import viewSlice from './view'


const store = configureStore({
  reducer: {
    user: userReducer,
    area: areaReducer,
    goal: goalReducer,
    resource: resourceSlice,
    customer: customerSlice,
    contact: contactSlice,
    session: sessionSlice,
    view: viewSlice,
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;