import React from 'react';
import intl from 'react-intl-universal';
import DisplayDate from '../../../../components/DisplayDate';
import styles from './Styles';

interface SessionHeaderProps {
  sessionNum: number;
  date: string;
}

const SessionHeader: React.FC<SessionHeaderProps> = ({ sessionNum, date }) => {
  return (
    <span>
      {intl.get('sessions.session')}
      {' '}
      {sessionNum}
      {' '}
      <DisplayDate value={date} style={styles.date} showDate showTime />
    </span>
  );
}

export default SessionHeader;
