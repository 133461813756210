import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout, Button, theme, Flex } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import MainMenu from './components/MainMenu';
import UserMenu from './components/UserMenu';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as LogoComplete } from '../../images/logo-complete.svg';
import getStyles from './Styles';

const { Header, Content, Sider } = Layout;

interface RootProps {
  isSessionValid: () => void;
}

const Root: React.FC<RootProps> = ({ isSessionValid }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { token } = theme.useToken();
  const styles = getStyles(token);

  return (
    <Layout style={styles.mainLayout}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div style={collapsed ? styles.logoSmall : styles.logo}>
          {collapsed ?
            <Logo height={43} width={'auto'} />
            :
            <LogoComplete height={43} width={'auto'} fill='#ffffff' />
          }
        </div>
        <MainMenu />
      </Sider>
      <Layout>
        <Header style={styles.header}>
          <Flex style={styles.boxStyle} justify={'space-between'} align={'center'}>
            <Button
              shape="circle"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={styles.drawerToggle}
            />
            <UserMenu isSessionValid={isSessionValid} />
          </Flex>
        </Header>
        <Content style={styles.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Root;
