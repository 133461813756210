import { FieldData } from "./types";

export const parseContactToFieldData = (contact: Contact): FieldData[] => {
  if (!contact) return [];
  return (Object.keys(contact) as Array<keyof Contact>).map((contactKey) => ({
    name: contactKey,
    value: contact[contactKey],
  }))
}

export const parseFieldDataToContact = (fields: FieldData[]): Contact => {
  return (fields.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.value }), {})) as Contact;
}
