import { API } from "aws-amplify";
import config from "../config";

const API_NAME = config.apiGateway[0].name;

export const getGoals = () => {
  const path = 'goals';
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const getGoal = (goalId: number) => {
  const path = `goals/${goalId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const addGoal = (name: string, areaId: number) => {
  const path = 'goals';
  const myInit = {
    body: { name, areaId },
    headers: {},
    response: false,
  };

  return API.put(API_NAME, path, myInit);
}

export const updateGoal = (goalId: number, name: string) => {
  const path = `goals/${goalId}`;
  const myInit = {
    body: { name },
    headers: {},
    response: false,
  };

  return API.post(API_NAME, path, myInit);
}

export const deleteGoal = (goalId: number) => {
  const path = `goals/${goalId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.del(API_NAME, path, myInit);
}

