import { NumericDictionary, keyBy } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { addArea, deleteArea, getArea, getAreas, updateArea } from '../../api/area';

interface AreaState {
  data: NumericDictionary<Area>;
  status: Status;
  createStatus: Status;
  error: string | null | undefined;
}

const initialState: AreaState = {
  data: {},
  status: 'idle',
  createStatus: 'idle',
  error: null
}

export const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAreas.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchAreas.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.createStatus = 'idle'
        state.data = keyBy(action.payload.areas, 'id')
      })
      .addCase(fetchAreas.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchArea.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchArea.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const newArea = action.payload;
        state.data = { ...state.data, [newArea.id]: newArea };
      })
      .addCase(fetchArea.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(triggerCreateArea.pending, (state) => {
        state.createStatus = 'loading'
      })
      .addCase(triggerCreateArea.fulfilled, (state) => {
        state.createStatus = 'succeeded'
      })
      .addCase(triggerCreateArea.rejected, (state) => {
        state.createStatus = 'failed'
      })
  }
})

const selectAreaData = (state: RootState) => state.area.data
const selectAreaStatus = (state: RootState) => state.area.status
const selectAreaCreateStatus = (state: RootState) => state.area.createStatus
const selectAreaError = (state: RootState) => state.area.error

export const selectors = {
  selectAreaData,
  selectAreaStatus,
  selectAreaCreateStatus,
  selectAreaError
}

const fetchAreas = createAsyncThunk('getAreas', async () => await getAreas())
const fetchArea = createAsyncThunk('getArea', async ({ areaId }: { areaId: number }) => await getArea(areaId))
const triggerUpdateArea = createAsyncThunk('updateArea',
  async ({ areaId, name }: { areaId: number, name: string }, { dispatch }) => {
    const response = await updateArea(areaId, name)
    dispatch(fetchAreas())
    return response;
  }
)
const triggerCreateArea = createAsyncThunk('addArea',
  async ({ name }: { name: string }, { dispatch }) => {
    const response = await addArea(name)
    dispatch(fetchAreas())
    return response;
  }
)
const triggerDeleteArea = createAsyncThunk('deleteArea',
  async ({ areaId }: { areaId: number }, { dispatch }) => {
    const response = await deleteArea(areaId)
    dispatch(fetchAreas())
    return response;
  }
)

export const actions = {
  fetchAreas,
  fetchArea,
  triggerUpdateArea,
  triggerCreateArea,
  triggerDeleteArea
}

export default areaSlice.reducer