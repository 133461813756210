import React from 'react';
import { Button, Flex, Space, Typography, Form, Input, Tooltip } from 'antd';
import styles from './Styles';
import intl from 'react-intl-universal';
import { Link, Navigate } from 'react-router-dom';
import { LeftOutlined, MailOutlined } from '@ant-design/icons';
import FormText from '../../../../components/FormText';
import FormArea from '../../../../components/FormArea';
import EditButton from '../../../../components/EditButton';
import BasePage from '../../../../components/BasePage';
import { CommonProps, FieldData } from './types';
import { PATHS, buildPath } from '../../../paths';

export interface ContactDetailProps extends CommonProps {
  readOnlyMode: boolean;
  fields: FieldData[];
  setReadOnlyMode: (newValue: boolean) => void;
  setFields: (fields: FieldData[]) => void;
  onFinish: () => void;
}

const ContactDetail: React.FC<ContactDetailProps> = ({ mode, customerId, status, submitStatus, readOnlyMode, fields, setReadOnlyMode, setFields, onFinish }) => {
  return (
    <div>
      <Flex style={styles.titleContainer} justify={'left'} align={'center'}>
        <Link to={buildPath(PATHS.CUSTOMER, { customerId })}>
          <Button shape="circle" icon={<LeftOutlined />} />
        </Link>

        <Typography.Title level={3} style={styles.title}>
          {mode === 'new' ? intl.get('contact.new.title') : intl.get('contact.update.title')}
        </Typography.Title>
      </Flex>
      <BasePage statuses={[status]} extraCheck={!fields.length}>


        <Space direction="vertical" size="middle" style={styles.space}>
          <Form
            fields={fields}
            onFieldsChange={(_, allFields) => {
              setFields(allFields);
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={onFinish}
          >
            <Form.Item wrapperCol={{ offset: 14, span: 4 }}>
              <EditButton readOnlyMode={readOnlyMode} onToggle={() => setReadOnlyMode(!readOnlyMode)} />
            </Form.Item>
            <Form.Item label={intl.get('contact.dni.label')} name={'dni'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('contact.name.label')} name={'name'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('contact.surname.label')} name={'surname'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('contact.persona.label')} name={'persona'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('contact.phone.label')} name={'phone'}>
              {readOnlyMode ? (<FormText />) : (<Input type='tel' />)}
            </Form.Item>
            <Form.Item label={intl.get('contact.email.label')} name={'email'}>
              {readOnlyMode ? (
                <FormText
                  suffix={(email) => (
                    <Tooltip title={intl.get('contacts.sendEmail.title')}>
                      <Typography.Link
                        href={`mailto:${email}`}
                        rel="noopener noreferrer"
                      >
                        <MailOutlined style={styles.emailIcon} />
                      </Typography.Link>
                    </Tooltip>
                  )}
                />
              ) : (<Input type='email' />)}
            </Form.Item>
            <Form.Item label={intl.get('contact.description.label')} name={'description'}>
              {readOnlyMode ? (<FormArea />) : (<Input.TextArea rows={5} />)}
            </Form.Item>

            {!readOnlyMode && (
              <Form.Item wrapperCol={{ offset: 14, span: 4 }}>
                <Flex style={styles.boxStyle} justify={'right'} align={'center'} gap={"small"}>
                  <Link to={buildPath(PATHS.CUSTOMER, { customerId })}>
                    <Button>
                      {intl.get('cancel')}
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit" loading={submitStatus === 'loading'}>
                    {mode === 'new' ? intl.get('create') : intl.get('save')}
                  </Button>
                </Flex>
              </Form.Item>
            )}
          </Form>
        </Space>
      </BasePage>
      {submitStatus === 'succeeded' && (<Navigate to={buildPath(PATHS.CUSTOMER, { customerId })} />)}
    </div >
  );
}

export default ContactDetail;
