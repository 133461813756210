import React, { useEffect } from 'react';
import { NumericDictionary, groupBy } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as sessionActions, selectors as sessionSelectors } from '../../store/session';
import { actions as customerActions, selectors as customerSelectors } from '../../store/customer';
import { actions as viewActions, selectors as viewSelectors } from '../../store/view';
import { actions as resourceActions, selectors as resourceSelectors } from '../../store/resource';
import Sessions from './Sessions';

const SessionsContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const sessionData = useAppSelector(sessionSelectors.selectSessionData);
  const sessionsByCustomer: NumericDictionary<Array<Session>> = sessionData && Object.values(sessionData).length
    ? groupBy(Object.values(sessionData), 'customer_id')
    : {};
  const sessionStatus = useAppSelector(sessionSelectors.selectSessionStatus);
  const sessionCreateStatus = useAppSelector(sessionSelectors.selectSessionCreateStatus);
  const customerData = useAppSelector(customerSelectors.selectCustomerData);
  const customerStatus = useAppSelector(customerSelectors.selectCustomerStatus);
  const resourceData = useAppSelector(resourceSelectors.selectResourceData);
  const resourceStatus = useAppSelector(resourceSelectors.selectResourceStatus);

  const currentCustomer = useAppSelector(viewSelectors.selectSessionsCurrentCustomer);

  useEffect(() => {
    dispatch(sessionActions.fetchSessions());
    dispatch(customerActions.fetchCustomers());
    dispatch(resourceActions.fetchResources());
  }, []);

  useEffect(() => {
    if (!currentCustomer && customerData && Object.values(customerData).length) {
      handleChangeCurrentCustomer(Object.values(customerData)[0].id)
    }
  }, [customerData]);

  const handleDeleteSession = (sessionId: number) => {
    dispatch(sessionActions.triggerDeleteSession({ sessionId }));
  }

  const handleChangeCurrentCustomer = (customerId: number) => {
    dispatch(viewActions.setSessionsCurrentCustomer(customerId));
  }

  return (
    <Sessions
      sessionsByCustomer={sessionsByCustomer}
      sessionStatus={sessionStatus}
      sessionCreateStatus={sessionCreateStatus}
      customerData={customerData}
      customerStatus={customerStatus}
      resourceData={resourceData}
      resourceStatus={resourceStatus}
      currentCustomer={currentCustomer}
      changeCurrentCustomer={handleChangeCurrentCustomer}
      deleteSession={handleDeleteSession}
    />
  );
}

export default SessionsContainer;
