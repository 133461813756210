import React, { useEffect, useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { RouterProvider } from 'react-router-dom';
import { App as AntApp, Flex, Spin } from 'antd';
import { Provider } from 'react-redux';
import getRouter from './routes';
import store from './store';
import config from './config';
import { setCurrentLocale } from './localization';


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: config.apiGateway.map((endpoint) => {
      return {
        ...endpoint,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          };
        }
      };
    }),
  }
});

const boxStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [initIntlDone, setInitIntlDone] = React.useState(false);

  const initializeIntl = () => {
    setCurrentLocale();
    setInitIntlDone(true);
  }


  const isSessionValid = async () => {
    const session = await Auth.currentSession()
      .then(session => {
        return Promise.resolve(session);
      })
      .catch(err => {
        console.log(err);
        return Promise.resolve(undefined);
      });
    setIsAuthenticated(!!session?.isValid());
  };

  const loadSession = async () => {
    try {
      await isSessionValid();
    }
    catch (e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    initializeIntl();
    loadSession();
  }, []);

  return (
    isAuthenticating || !initIntlDone ?
      <Flex style={boxStyle} justify={'center'} align={'center'}>
        <Spin size="large" />
      </Flex>
      :
      <Provider store={store}>
        <AntApp style={{ height: '100%' }}>
          <RouterProvider router={getRouter(isSessionValid, isAuthenticated)} />
        </AntApp>
      </Provider>
  );
}

export default App;