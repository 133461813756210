import React, { useState } from 'react';
import { Typography } from 'antd';
import styles from './Styles';

const { Paragraph } = Typography;

interface EditableTextProps {
  originalValue: string;
  onEditEnd: (value: string) => void;
}

const EditableText: React.FC<EditableTextProps> = ({ originalValue, onEditEnd }) => {
  const [value, setValue] = useState(originalValue)

  const handleChange = (newValue: string) => {
    setValue(newValue)
    if (originalValue !== newValue) {
      onEditEnd(newValue)
    }
  }

  return (
    <Paragraph editable={{ onChange: handleChange, }} style={styles.paragraph}>
      {value}
    </Paragraph>
  );
}

export default EditableText;
