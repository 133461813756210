import React from 'react';
import styles from './Styles';
import { Card, Empty, List, Space, Typography } from 'antd';
import { NumericDictionary } from 'lodash';
import intl from 'react-intl-universal';

const { Paragraph } = Typography;

interface SessionHeaderProps {
  session: Session;
  resourceData: NumericDictionary<Resource>;
}

const SessionHeader: React.FC<SessionHeaderProps> = ({ session, resourceData }) => {
  return (
    <Space direction="vertical" size="middle" style={styles.space}>
      <List
        size="small"
        bordered
        header={<b>{intl.get('session.resources.label')}</b>}
        dataSource={session.resources}
        renderItem={(item) => <List.Item>{resourceData[item.resource_id]?.name}</List.Item>}
      />
      <Card title={<b>{intl.get('session.notes.label')}</b>} size="small">
        <Paragraph style={styles.paragraph}>
          <pre style={styles.pre}>
            {session.notes ? session.notes : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </pre>
        </Paragraph>
      </Card>
    </Space>
  );
}

export default SessionHeader;
