import { NumericDictionary, keyBy } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { addCustomer, deleteCustomer, getCustomer, getCustomers, updateCustomer } from '../../api/customer';

export interface CustomerFromDB {
  id: number;
  name: string;
  surname: string;
  birth_date: string;
  school_grade?: string;
  address: string;
  postal_code: number;
  city: string;
  notes?: string;
  contacts: Array<number>;
}

const parseCustomerFromDbToJs = ({ id, name, surname, birth_date, school_grade, address, postal_code, city, notes, contacts }: CustomerFromDB): Customer => ({
  id,
  name,
  surname,
  birthDate: birth_date,
  schoolGrade: school_grade,
  address,
  postalCode: postal_code,
  city,
  notes,
  contacts,
});

interface CustomerState {
  data: NumericDictionary<Customer>;
  status: Status;
  createStatus: Status;
  updateStatus: Status;
  error: string | null | undefined;
}

const initialState: CustomerState = {
  data: {},
  status: 'idle',
  createStatus: 'idle',
  updateStatus: 'idle',
  error: null
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.updateStatus = 'idle'
        state.createStatus = 'idle'
        const customersWithParsedDate = action.payload.customers.map(parseCustomerFromDbToJs);
        state.data = keyBy(customersWithParsedDate, 'id')
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchCustomer.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const newCustomer = parseCustomerFromDbToJs(action.payload);
        state.data = { ...state.data, [newCustomer.id]: newCustomer };
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(triggerCreateCustomer.pending, (state) => {
        state.createStatus = 'loading'
      })
      .addCase(triggerCreateCustomer.fulfilled, (state) => {
        state.createStatus = 'succeeded'
      })
      .addCase(triggerCreateCustomer.rejected, (state) => {
        state.createStatus = 'failed'
      })
      .addCase(triggerUpdateCustomer.pending, (state) => {
        state.updateStatus = 'loading'
      })
      .addCase(triggerUpdateCustomer.fulfilled, (state) => {
        state.updateStatus = 'succeeded'
      })
      .addCase(triggerUpdateCustomer.rejected, (state) => {
        state.updateStatus = 'failed'
      })
  }
})

const selectCustomerData = (state: RootState) => state.customer.data
const selectCustomer = (state: RootState, customerId: number) => state.customer.data[customerId]
const selectCustomerStatus = (state: RootState) => state.customer.status
const selectCustomerCreateStatus = (state: RootState) => state.customer.createStatus
const selectCustomerUpdateStatus = (state: RootState) => state.customer.updateStatus
const selectCustomerError = (state: RootState) => state.customer.error

export const selectors = {
  selectCustomerData,
  selectCustomer,
  selectCustomerStatus,
  selectCustomerCreateStatus,
  selectCustomerUpdateStatus,
  selectCustomerError
}

const fetchCustomers = createAsyncThunk('getCustomers', async () => await getCustomers())
const fetchCustomer = createAsyncThunk('getCustomer', async ({ customerId }: { customerId: number }) => await getCustomer(customerId))
const triggerUpdateCustomer = createAsyncThunk('updateCustomer',
  async (customer: Customer, { dispatch }) => {

    console.log('updateCustomer', customer)
    const response = await updateCustomer(customer)
    dispatch(fetchCustomers())
    return response;
  }
)
const triggerCreateCustomer = createAsyncThunk('addCustomer',
  async (customer: Omit<Customer, 'id'>, { dispatch }) => {
    const response = await addCustomer(customer)
    dispatch(fetchCustomers())
    return response;
  }
)
const triggerDeleteCustomer = createAsyncThunk('deleteCustomer',
  async ({ customerId }: { customerId: number }, { dispatch }) => {
    const response = await deleteCustomer(customerId)
    dispatch(fetchCustomers())
    return response;
  }
)

export const actions = {
  fetchCustomers,
  fetchCustomer,
  triggerUpdateCustomer,
  triggerCreateCustomer,
  triggerDeleteCustomer
}

export default customerSlice.reducer