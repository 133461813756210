import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as contactActions, selectors as contactSelectors } from '../../store/contact';
import ContactDetail from './components/ContactDetail';
import { useParams } from 'react-router-dom';

const NewContactContainer: React.FC = () => {
  const params = useParams();
  const customerId = params.customerId ? Number.parseInt(params.customerId) : 0;

  const dispatch = useAppDispatch();
  const createStatus = useAppSelector(contactSelectors.selectContactCreateStatus);

  const handleCreateContact = (newContact: Omit<Contact, 'id' | 'customer_id'>) => {
    console.log('handleCreateContact', { ...newContact, customer_id: customerId })
    dispatch(contactActions.triggerCreateContact({ ...newContact, customer_id: customerId }));
  }

  return (
    <ContactDetail
      mode="new"
      customerId={customerId}
      contact={{
        id: -1,
        dni: "",
        name: "",
        surname: "",
        persona: "",
        phone: "",
        email: "",
        description: "",
        customer_id: customerId,
      }}
      status={'succeeded'}
      submitStatus={createStatus}
      onSubmit={handleCreateContact}
    />
  );
}

export default NewContactContainer;
