import React, { ReactNode } from 'react';
import styles from '../Styles';

interface ItemWrapperProps {
  children: ReactNode[];
  style?: React.CSSProperties;
}

const ItemWrapper = React.forwardRef<HTMLDivElement, ItemWrapperProps>(
  function InternalItemWrapper({ children, style }, ref) {
    return (
      <div style={{ ...styles.itemWrapper, ...style }} ref={ref}>{children}</div>
    );
  }
);

export default ItemWrapper;