import React from 'react';
import intl from 'react-intl-universal';
import { Avatar, Divider, Dropdown, MenuProps, Spin, theme } from 'antd';
import { LogoutOutlined, TranslationOutlined } from '@ant-design/icons';
import getStyles from './Styles';

const { useToken } = theme;

interface LangItem {
  key: string;
  label: string;
}

interface UserMenuProps {
  userData?: User;
  userStatus: Status;
  initials?: string;
  langugageItems: LangItem[]
  onMenuItemClick: ({ key }: { key: string }) => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ userData, userStatus, initials, langugageItems, onMenuItemClick }) => {

  const items: MenuProps['items'] = [
    {
      key: 'lang',
      label: intl.get('userMenu.language'),
      icon: <TranslationOutlined />,
      children: langugageItems
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: intl.get('userMenu.signOut'),
    },
  ];

  const { token } = useToken();
  const styles = getStyles(token);

  return (
    <Dropdown
      menu={{ items, onClick: onMenuItemClick, selectedKeys: [`lang/${intl.getInitOptions().currentLocale}`] }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      trigger={['click']}
      dropdownRender={(menu) => (
        <div style={styles.contentStyle}>
          <div style={styles.name}><b>{userData?.name}</b></div>
          <div style={styles.email}>{userData?.email}</div>
          <Divider style={styles.divider} />
          {React.cloneElement(menu as React.ReactElement, { style: styles.menuStyle })}
        </div >
      )}
    >
      <Avatar style={styles.avatar}>
        {userStatus !== 'succeeded' ? <Spin size="small" /> : initials}
      </Avatar>
    </Dropdown >
  );
}

export default UserMenu;
