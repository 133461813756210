import React from 'react';
import { Button, Collapse, Empty, Select, Space, Typography } from 'antd';
import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { NumericDictionary } from 'lodash';
import { Link } from 'react-router-dom';
import SessionHeader from './components/SessionHeader';
import SessionBody from './components/SessionBody';
import DeleteButton from '../../components/DeleteButton';
import BasePage from '../../components/BasePage';
import styles from './Styles';
import { PATHS, buildPath } from '../paths';

interface SessionsProps {
  sessionsByCustomer: NumericDictionary<Session[]>;
  sessionStatus: Status;
  sessionCreateStatus: Status;
  customerData: NumericDictionary<Customer>;
  customerStatus: Status;
  resourceData: NumericDictionary<Resource>;
  resourceStatus: Status;
  currentCustomer?: number;
  changeCurrentCustomer: (customerId: number) => void;
  deleteSession: (sessionId: number) => void;
}

const Sessions: React.FC<SessionsProps> = ({
  sessionsByCustomer,
  sessionStatus,
  sessionCreateStatus,
  customerData,
  customerStatus,
  resourceData,
  resourceStatus,
  currentCustomer,
  changeCurrentCustomer,
  deleteSession
}) => {
  return (
    <div>
      <Typography.Title level={3} style={styles.title}>
        {intl.get('mainMenu.sessions')}
      </Typography.Title>

      <BasePage
        statuses={[sessionStatus, customerStatus, resourceStatus]}
        empty={!Object.keys(customerData).length}
      >
        <Space direction="vertical" size="middle" style={styles.space}>
          <Space>
            <span>{intl.get('sessions.currentCustomer')}{':'}</span>
            <Select
              value={currentCustomer}
              onChange={changeCurrentCustomer}
              options={[...Object.values(customerData) as Array<Customer>].map((customer) => (
                { value: customer.id, label: `${customer.name} ${customer.surname}` }
              ))}
            />
            <Link to={buildPath(PATHS.CUSTOMER, { customerId: currentCustomer || '' })}>
              <Button
                icon={<EyeOutlined />}
              >
                {intl.get('sessions.goToCustomer')}
              </Button>
            </Link>
            <Link to={buildPath(PATHS.NEW_SESSION)}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                loading={sessionCreateStatus === 'loading'}
              >
                {intl.get('sessions.newSession')}
              </Button>
            </Link>
          </Space>
          {currentCustomer && sessionsByCustomer[currentCustomer] ?
            <Collapse
              items={sessionsByCustomer[currentCustomer].map((session, idx) => ({
                key: session.id,
                label: (<SessionHeader sessionNum={idx + 1} date={session.date} />),
                children: <SessionBody session={session} resourceData={resourceData} />,
                extra: (
                  <Space>
                    <Link to={buildPath(PATHS.SESSION, { sessionId: session.id })}>
                      <EditOutlined />
                    </Link>
                    <DeleteButton
                      title={intl.get('sessions.deleteSession.title')}
                      description={intl.get('sessions.deleteSession.description')}
                      onConfirm={() => deleteSession(session.id)}
                    />
                  </Space>
                )
              }))}
            />
            :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
        </Space>
      </BasePage>
    </div >
  );
}

export default Sessions;
