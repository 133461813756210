import React from 'react';
import intl from 'react-intl-universal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Transfer } from 'antd';
import type { TransferItem } from 'antd/es/transfer';
import DraggableItem from './subcomponents/DraggableItem';

interface SortableTransferProps {
  dataSource: TransferItem[];
  targetKeys?: string[];
  disabled?: boolean;
  onChange?: (targetKeys: string[]) => void;
}

const SortableTransfer: React.FC<SortableTransferProps> = ({ dataSource, targetKeys = [], disabled, onChange = () => { } }) => {
  const handleChange = (nextTargetKeys: string[]) => {
    onChange(Array.from(new Set(nextTargetKeys)));
  };

  const moveRow = async (dragIndex: number, hoverIndex: number) => {
    const clonedList = targetKeys;
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    handleChange(clonedList);
  };

  const filterOption = (inputValue: string, option: TransferItem) =>
    (option.title || '').toUpperCase().indexOf(inputValue.toUpperCase()) > -1;

  return (
    <DndProvider backend={HTML5Backend}>
      <Transfer
        disabled={disabled}
        listStyle={{ flex: 1 }}
        dataSource={dataSource}
        render={(it) => (
          <DraggableItem
            index={targetKeys.findIndex((key) => key === it.key)}
            label={it.title}
            moveRow={moveRow}
          />
        )}
        titles={[
          intl.get('session.transfer.origin'),
          intl.get('session.transfer.target')
        ]}
        targetKeys={targetKeys}
        showSearch
        filterOption={filterOption}
        onChange={handleChange}
      />
    </DndProvider>
  );
};

export default SortableTransfer;