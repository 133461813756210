const styles: { [key: string]: React.CSSProperties } = {
  form: {
    width: 400
  },
  title: {
    textAlign: 'center'
  }
};

export default styles;

