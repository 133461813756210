import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ColumnHeightOutlined } from '@ant-design/icons';
import ItemWrapper from './ItemWrapper';
import styles from '../Styles';


const type = "DraggableItem";

interface DraggableItemProps {
  index: number;
  label?: string;
  moveRow: (idxA: number, idxB: number) => void;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ index, label, moveRow }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isOver, dropPosition }, drop] = useDrop({
    accept: type,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    collect: (monitor: any) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropPosition:
          dragIndex < index ? 'downward' : 'upward'
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    drop: (item: any) => {
      moveRow(item.index, index);
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  preview(drop(ref));

  const style = isOver ? (
    dropPosition === 'downward'
      ? styles.downward
      : styles.upward
  ) : undefined

  return (
    <ItemWrapper
      key={label}
      ref={ref}
      style={style}
    >
      <span style={styles.label}>{label}</span>
      {index !== -1 && (
        <span ref={drag} style={isDragging ? styles.handleGrabbing : styles.handleGrab}>
          <ColumnHeightOutlined />
        </span>
      )}
    </ItemWrapper>
  );
};

export default DraggableItem;