import React, { useState } from 'react';
import { Form, App } from 'antd';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import intl from 'react-intl-universal';
import Login from './Login';
import { FieldType } from './types';
import { PATHS, buildPath } from '../paths';
import useScreenSize from '../../hooks/useScreenSize';

interface LoginContainerProps {
  isSessionValid: () => void;
}

const LoginContainer: React.FC<LoginContainerProps> = ({ isSessionValid }) => {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const screenSize = useScreenSize();
  const isMobileLayout = screenSize.width < 1100;

  const isValid = () => {
    if (!values) return false
    const { email, password } = values;
    if (!email) return false;
    if (!password) return false;
    if (email === '') return false;
    if (password === '') return false;
    return true
  }

  const onLogIn = async (values: FieldType) => {
    const { email, password } = values;
    if (email && password) {
      setLoading(true);

      try {
        const response = await Auth.signIn(email, password)

        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
          setCognitoUser(response);
        } else {
          await isSessionValid();
          navigate(buildPath(PATHS.ROOT));
        }
      } catch (e) {
        console.log(e);
        notification.error({
          message: intl.get('error'),
          description: intl.get('login.signInError'),
          placement: 'topRight',
        });
        setLoading(false);
      }
    }
  };

  return (
    <Login
      form={form}
      cognitoUser={cognitoUser}
      loading={loading}
      isMobileLayout={isMobileLayout}
      onLogIn={onLogIn}
      isSessionValid={isSessionValid}
      isValid={isValid}
    />
  );
}

export default LoginContainer;
