import { Tag } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const DATE_FORMAT = 'DD/MM/YYYY';
const TIME_FORMAT = 'HH:mm';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

interface DisplayDateProps {
  value?: string;
  showDate?: boolean;
  showTime?: boolean;
  style?: React.CSSProperties;
}

const DisplayDate: React.FC<DisplayDateProps> = ({ value, showDate = false, showTime = false, style }) => {
  let format = DATE_FORMAT;

  if (!showDate && showTime) {
    format = TIME_FORMAT
  } else if (showDate && showTime) {
    format = DATE_TIME_FORMAT
  }

  return (
    <Tag style={style} color="processing">{dayjs(value).format(format)}</Tag>
  );
}

export default DisplayDate;
