import { NumericDictionary, keyBy } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { addContact, deleteContact, getContact, getContacts, updateContact } from '../../api/contact';
import { actions as customerActions } from '../customer'

interface ContactState {
  data: NumericDictionary<Contact>;
  status: Status;
  createStatus: Status;
  updateStatus: Status;
  error: string | null | undefined;
}

const initialState: ContactState = {
  data: {},
  status: 'idle',
  createStatus: 'idle',
  updateStatus: 'idle',
  error: null
}

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchContacts.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.updateStatus = 'idle'
        state.createStatus = 'idle'
        state.data = keyBy(action.payload.contacts, 'id')
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchContact.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const newContact = action.payload;
        state.data = { ...state.data, [newContact.id]: newContact };
      })
      .addCase(fetchContact.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(triggerCreateContact.pending, (state) => {
        state.createStatus = 'loading'
      })
      .addCase(triggerCreateContact.fulfilled, (state) => {
        state.createStatus = 'succeeded'
      })
      .addCase(triggerCreateContact.rejected, (state) => {
        state.createStatus = 'failed'
      })
      .addCase(triggerUpdateContact.pending, (state) => {
        state.updateStatus = 'loading'
      })
      .addCase(triggerUpdateContact.fulfilled, (state) => {
        state.updateStatus = 'succeeded'
      })
      .addCase(triggerUpdateContact.rejected, (state) => {
        state.updateStatus = 'failed'
      })
  }
})

const selectContactData = (state: RootState) => state.contact.data
const selectContact = (state: RootState, contactId: number) => state.contact.data[contactId]
const selectContactStatus = (state: RootState) => state.contact.status
const selectContactCreateStatus = (state: RootState) => state.contact.createStatus
const selectContactUpdateStatus = (state: RootState) => state.contact.updateStatus
const selectContactError = (state: RootState) => state.contact.error

export const selectors = {
  selectContactData,
  selectContact,
  selectContactStatus,
  selectContactCreateStatus,
  selectContactUpdateStatus,
  selectContactError
}

const fetchContacts = createAsyncThunk('getContacts', async () => await getContacts())
const fetchContact = createAsyncThunk('getContact', async ({ contactId }: { contactId: number }) => await getContact(contactId))
const triggerUpdateContact = createAsyncThunk('updateContact',
  async (contact: Contact, { dispatch }) => {
    const response = await updateContact(contact)
    dispatch(fetchContacts())
    return response;
  }
)
const triggerCreateContact = createAsyncThunk('addContact',
  async (contact: Omit<Contact, 'id'>, { dispatch }) => {
    const response = await addContact(contact)
    dispatch(fetchContacts())
    dispatch(customerActions.fetchCustomer({ customerId: contact.customer_id }))
    return response;
  }
)
const triggerDeleteContact = createAsyncThunk('deleteContact',
  async ({ contactId, customerId }: { contactId: number, customerId: number }, { dispatch }) => {
    const response = await deleteContact(contactId)
    dispatch(customerActions.fetchCustomer({ customerId }))
    dispatch(fetchContacts())
    return response;
  }
)

export const actions = {
  fetchContacts,
  fetchContact,
  triggerUpdateContact,
  triggerCreateContact,
  triggerDeleteContact
}

export default contactSlice.reducer