const config = {
  apiGateway: [
      {
          name: 'TFMBackend',
          endpoint: 'https://api.saracillan.com/api/',
      },
  ],
  cognito: {
      REGION: 'us-east-1',
      USER_POOL_ID: 'us-east-1_JxLQcawfV',
      APP_CLIENT_ID: '2i97d5m35ajb50lmg0eba84h0',
  }
};

export default config;