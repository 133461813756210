import React, { useEffect, useState } from 'react';
import { CommonProps, FieldData } from './types';
import { parseCustomerToFieldData, parseFieldDataToCustomer } from './utils';
import CustomerDetail from './CustomerDetail';

interface CustomerDetailContainerProps extends CommonProps {
  onSubmit: (customer: Customer) => void;
}

const CustomerDetailContainer: React.FC<CustomerDetailContainerProps> = ({ mode, customer, status, submitStatus, onSubmit }) => {
  const [readOnlyMode, setReadOnlyMode] = useState(mode === 'new' ? false : true);
  const [fields, setFields] = useState<FieldData[]>(parseCustomerToFieldData(customer));

  useEffect(() => {
    setFields(parseCustomerToFieldData(customer));
  }, [customer]);

  const handleSaveChanges = () => {
    onSubmit(parseFieldDataToCustomer(fields));
  }

  return (
    <CustomerDetail
      mode={mode}
      customer={customer}
      status={status}
      submitStatus={submitStatus}
      readOnlyMode={readOnlyMode}
      fields={fields}
      setReadOnlyMode={setReadOnlyMode}
      setFields={setFields}
      saveChanges={handleSaveChanges}
    />
  );
}

export default CustomerDetailContainer;
