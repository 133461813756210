import React from "react";
import intl from "react-intl-universal";
import { Button, Flex, Typography } from "antd";
import { Link, isRouteErrorResponse, useRouteError } from "react-router-dom";
import Oops from '../../images/oops.png';
import { PATHS, buildPath } from "../paths";

const boxStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

const ErrorPage = () => {
  const error = useRouteError();

  return (
    <Flex style={boxStyle} justify={'center'} align={'center'} vertical>
      <img src={Oops} alt="Oops!" width={650} />
      {isRouteErrorResponse(error) && (
        <Typography.Title level={2}>{error.status} - {error.statusText}</Typography.Title>
      )}
      <p><i>{intl.get('error.message')}</i></p>
      <Link to={buildPath(PATHS.ROOT)}><Button type='primary'>{intl.get('error.goHome')}</Button></Link>
    </Flex>
  );
}

export default ErrorPage;