import React from 'react';
import { Typography } from 'antd';
import styles from './Styles';

interface FormAreaProps {
  value?: string;
}

const FormArea: React.FC<FormAreaProps> = ({ value = '' }) => {
  return (
    <Typography.Paragraph>
      <pre style={styles.pre}>
        {value}
      </pre>
    </Typography.Paragraph>
  );
}

export default FormArea;
