import React from 'react';
import intl from 'react-intl-universal';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { HomeOutlined, CalendarOutlined, UsergroupAddOutlined, BookOutlined, TrophyOutlined } from '@ant-design/icons';
import { PATHS, buildPath } from '../../../paths';

const MainMenu: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onMenuItemClick = ({ key }: { key: string }) => {
    switch (key) {
      case 'home':
        navigate(buildPath(PATHS.HOME));
        break;
      case 'resources':
        navigate(buildPath(PATHS.RESOURCES));
        break;
      case 'goals':
        navigate(buildPath(PATHS.GOALS));
        break;
      case 'customers':
        navigate(buildPath(PATHS.CUSTOMERS));
        break;
      case 'sessions':
        navigate(buildPath(PATHS.SESSIONS));
        break;
      default:
        break;
    }
  }

  const MENU_ITEMS = [
    {
      key: 'home',
      icon: <HomeOutlined />,
      label: intl.get('mainMenu.home'),
    },
    {
      key: 'sessions',
      icon: <CalendarOutlined />,
      label: intl.get('mainMenu.sessions'),
    },
    {
      key: 'customers',
      icon: <UsergroupAddOutlined />,
      label: intl.get('mainMenu.customers'),
    },
    {
      key: 'goals',
      icon: <TrophyOutlined />,
      label: intl.get('mainMenu.goals'),
    },
    {
      key: 'resources',
      icon: <BookOutlined />,
      label: intl.get('mainMenu.resources'),
    }
  ];


  return (
    <Menu
      mode="inline"
      theme='dark'
      selectedKeys={[pathname.split('/').filter((value) => !!value)[0]]}
      onClick={onMenuItemClick}
      items={MENU_ITEMS}
    />
  );
}

export default MainMenu;
