import { NumericDictionary } from "lodash";
import { FieldData, UpdatedResource } from "./types";

export const parseResourceToFieldData = (resource: Resource): FieldData[] => {
  if (!resource) return [];
  return (Object.keys(resource) as Array<keyof Resource>).map((resourceKey) => (resourceKey !== 'goals' ? {
    name: resourceKey,
    value: resource[resourceKey],
  } : {
    name: resourceKey,
    value: resource[resourceKey] ? resource[resourceKey]?.map((goalId) => `goal-${goalId}`) : undefined,
  }))
}

export const getGoalIdFromStringKey = (key: string): number => Number.parseInt(key.split('-')[1]);

export const parseFieldDataToResource = (fields: FieldData[]): Resource => {
  const resource = (fields.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.value }), {})) as UpdatedResource;
  return {
    ...resource,
    goals: resource && resource.goals ? resource.goals.map((goalId) => getGoalIdFromStringKey(goalId)) : undefined
  }
}

export const getDatasource = (areaData: NumericDictionary<Area>, goalData: NumericDictionary<Goal>) => {
  if (!Object.keys(areaData).length || !Object.keys(goalData).length) return [];
  return (Object.values(areaData) as Array<Area>).map((area) => ({
    key: `area-${area.id}`,
    title: area.name,
    disabled: true,
    children: area.goals ? area.goals.map((goalId) => ({
      key: `goal-${goalData[goalId].id}`,
      title: goalData[goalId].name,
    })) : undefined
  }))
}
