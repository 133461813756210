export const styles: { [key: string]: React.CSSProperties } = {
  boxStyle: {
    width: '100%',
    height: '100%',
  },
  titleContainer: {
    marginBottom: '25px',
    width: '100%',
    height: '100%',
  },
  title: {
    margin: 0,
    marginBottom: 0,
    marginLeft: '15px'
  },
  space: {
    display: 'flex'
  }
};

export default styles;