import React from 'react';

interface ValidatorIcon {
  error: boolean;
}

const ValidatorIcon: React.FC<ValidatorIcon> = ({ error }) => {
  return (
    <div style={{
      display: 'inline-block',
      backgroundColor: error ? '#f00' : '#7ed321',
      width: '8px',
      height: '8px',
      borderRadius: '100%',
      marginRight: '5px'
    }} />
  );
};


export default ValidatorIcon;