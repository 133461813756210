import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as areaActions, selectors as areaSelectors } from '../../store/area';
import { actions as goalActions, selectors as goalSelectors } from '../../store/goal';
import intl from 'react-intl-universal';
import Goals from './Goals';

const GoalsContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const areaData = useAppSelector(areaSelectors.selectAreaData);
  const areaStatus = useAppSelector(areaSelectors.selectAreaStatus);
  const areaCreateStatus = useAppSelector(areaSelectors.selectAreaCreateStatus);
  const goalData = useAppSelector(goalSelectors.selectGoalData);
  const goalStatus = useAppSelector(goalSelectors.selectGoalStatus);
  const goalCreateStatus = useAppSelector(goalSelectors.selectGoalCreateStatus);

  useEffect(() => {
    dispatch(areaActions.fetchAreas());
    dispatch(goalActions.fetchGoals());
  }, []);

  const handleAreaUpdate = (areaId: number, name: string) => {
    dispatch(areaActions.triggerUpdateArea({ areaId, name }));
  }

  const handleCreateArea = () => {
    dispatch(areaActions.triggerCreateArea({ name: intl.get('goals.newArea') }));
  }

  const handleDeleteArea = (areaId: number) => {
    dispatch(areaActions.triggerDeleteArea({ areaId }));
  }

  const handleAddGoal = async (areaId: number) => {
    dispatch(goalActions.triggerCreateGoal({ name: intl.get('goals.newGoal'), areaId }));
  }

  const handleGoalUpdate = (goalId: number, name: string) => {
    dispatch(goalActions.triggerUpdateGoal({ goalId, name }));
  }

  const handleDeleteGoal = (goalId: number, areaId: number) => {
    dispatch(goalActions.triggerDeleteGoal({ goalId, areaId }));
  }

  return (
    <Goals
      areaData={areaData}
      areaStatus={areaStatus}
      areaCreateStatus={areaCreateStatus}
      goalData={goalData}
      goalStatus={goalStatus}
      goalCreateStatus={goalCreateStatus}
      createArea={handleCreateArea}
      updateArea={handleAreaUpdate}
      deleteArea={handleDeleteArea}
      createGoal={handleAddGoal}
      updateGoal={handleGoalUpdate}
      deleteGoal={handleDeleteGoal}
    />
  );
}

export default GoalsContainer;
