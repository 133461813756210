import { GlobalToken } from "antd";

const getStyles = (token: GlobalToken): { [key: string]: React.CSSProperties } => ({
  mainLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  logo: {
    width: '180px',
    height: '52px',
    margin: '16px auto',
    padding: '5px'
  },
  logoSmall: {
    width: '52px',
    height: '52px',
    margin: '16px auto',
    padding: '5px'
  },
  header: {
    padding: 0,
    background: token.colorBgContainer
  },
  boxStyle: {
    width: '100%',
    height: '100%',
    padding: '15px'
  },
  drawerToggle: {
    fontSize: '16px',
    width: 44,
    height: 44
  },
  title: {
    textAlign: 'center',
    margin: 0
  },
  content: {
    margin: '16px',
    padding: 24,
    minHeight: 280,
    background: token.colorBgContainer,
    overflow: 'auto'
  }
});

export default getStyles;

