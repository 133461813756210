import React from 'react';
import { Flex, Button, Form, Input, Typography, Card, FormInstance } from 'antd';
import { CognitoUser } from '@aws-amplify/auth';
import PasswordCreator from './components/PasswordCreator';
import intl from 'react-intl-universal';
import styles from './Styles';
import loginBackground from '../../images/login.png';
import { ReactComponent as LogoComplete } from '../../images/logo-complete.svg';
import { FieldType } from './types';

interface LoginProps {
  form: FormInstance;
  cognitoUser?: CognitoUser;
  loading: boolean;
  isMobileLayout: boolean;
  onLogIn: (values: FieldType) => void;
  isSessionValid: () => void;
  isValid: () => boolean;
}

const Login: React.FC<LoginProps> = ({ form, cognitoUser, loading, isMobileLayout, onLogIn, isSessionValid, isValid }) => {

  return (
    <Flex style={styles.boxStyle} justify={'center'} align={'center'} >
      <Card style={styles.card} bodyStyle={styles.cardBody}>
        <Flex>
          {!isMobileLayout && <img src={loginBackground} width={600} />}
          <div style={styles.rightContainer}>
            <Flex style={styles.formContainer} justify={'center'} align={'center'}>
              {!cognitoUser ?
                <Form
                  form={form}
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={styles.form}
                  initialValues={{ remember: true }}
                  onFinish={onLogIn}
                  autoComplete="off"
                >
                  <Form.Item wrapperCol={{ offset: 0, span: 24 }} style={styles.titleItem}>
                    <Typography.Text style={styles.title}>
                      {intl.get('login.title1')}
                    </Typography.Text>
                    <br />
                    <LogoComplete width={300} height={'auto'} style={{ margin: '15px 0px' }} />
                  </Form.Item>
                  <Form.Item<FieldType>
                    label={intl.get('email')}
                    name="email"
                    rules={[{ required: true, message: intl.get('login.inputEmail') }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item<FieldType>
                    label={intl.get('password')}
                    name="password"
                    rules={[{ required: true, message: intl.get('login.inputPassword') }]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 14 }}>
                    <Button type="primary" htmlType="submit" disabled={!isValid()} loading={loading}>
                      {intl.get('login.signIn')}
                    </Button>
                  </Form.Item>
                </Form>
                :
                <PasswordCreator
                  cognitoUser={cognitoUser}
                  isSessionValid={isSessionValid}
                />
              }
            </Flex>
          </div>
        </Flex>
      </Card>
    </Flex>
  );
}

export default Login;
