import { API } from "aws-amplify";
import config from "../config";

const API_NAME = config.apiGateway[0].name;

export const getResources = () => {
  const path = 'resources';
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const getResource = (resourceId: number) => {
  const path = `resources/${resourceId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const addResource = (resource: Omit<Resource, 'id'>) => {
  const path = 'resources';
  const myInit = {
    body: resource,
    headers: {},
    response: false,
  };

  return API.put(API_NAME, path, myInit);
}

export const updateResource = (resourceId: number, updatedResource: Omit<Resource, 'id'>) => {
  const path = `resources/${resourceId}`;
  const myInit = {
    body: updatedResource,
    headers: {},
    response: false,
  };

  return API.post(API_NAME, path, myInit);
}

export const deleteResource = (resourceId: number) => {
  const path = `resources/${resourceId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.del(API_NAME, path, myInit);
}

