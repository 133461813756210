import React, { ChangeEvent } from 'react';
import { Input, Form, Button, Card, Typography } from 'antd';
import intl from 'react-intl-universal';
import ValidatorIcon from '../ValidatorIcon';
import styles from './Styles';
import { PasswordFields, ValidatorState } from './types';

interface PasswordCreatorProps {
  validator: ValidatorState;
  fields: PasswordFields;
  passError: boolean;
  loading: boolean;
  passwordInputChange: (name: keyof PasswordFields, evt: ChangeEvent<HTMLInputElement>) => void;
  onChangePassword: () => void;
}

const PasswordCreator: React.FC<PasswordCreatorProps> = ({ validator, fields, passError, loading, passwordInputChange, onChangePassword }) => {

  const { passEquals, passLength, upper, lower, number, special, } = validator;

  return (
    <Form
      name="basic"
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 13 }}
      style={styles.form}
      initialValues={{ remember: true }}
      autoComplete="off"
    >
      <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
        <Typography.Title level={3} style={styles.title}>
          {intl.get('password.title')}
        </Typography.Title>
      </Form.Item>
      <Form.Item<PasswordFields>
        label={intl.get('password')}
        name="password1"
        rules={[{ required: true, message: intl.get('password.inputNewPassword') }]}
      >
        <Input
          name='password1'
          type="password"
          value={fields.password1}
          onChange={(evt) => passwordInputChange('password1', evt)}
          status={passError ? 'error' : undefined}
        />
      </Form.Item>
      <Form.Item<PasswordFields>
        label={intl.get('password.repeatPassword')}
        name="password2"
        rules={[{ required: true, message: intl.get('password.inputRepeatPassword') }]}
      >
        <Input
          name='password2'
          type="password"
          value={fields.password2}
          onChange={(evt) => passwordInputChange('password2', evt)}
          status={passError ? 'error' : undefined}
        />

      </Form.Item>
      <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
        <Card>
          <ul>
            <div><ValidatorIcon error={passEquals} />{intl.get('password.errorEquals')}</div>
            <div><ValidatorIcon error={passLength} />{intl.get('password.errorLength')}</div>
            <div><ValidatorIcon error={upper} />{intl.get('password.errorUpper')}</div>
            <div><ValidatorIcon error={lower} />{intl.get('password.errorLower')}</div>
            <div><ValidatorIcon error={number} />{intl.get('password.errorNumber')}</div>
            <div><ValidatorIcon error={special} />{intl.get('password.errorSpecial')}</div>
          </ul>
        </Card>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" loading={loading} onClick={onChangePassword} disabled={passError}>
          {intl.get('password.changePassword')}
        </Button>
      </Form.Item>
    </Form >
  );
}

export default PasswordCreator;