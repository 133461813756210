import React from 'react';
import { Button, Empty, Flex, Result, Spin } from 'antd';
import styles from './Styles';
import intl from 'react-intl-universal';

interface BasePageProps {
  statuses: Array<Status>;
  extraCheck?: boolean;
  empty?: boolean;
  children: JSX.Element;
}

const BasePage: React.FC<BasePageProps> = ({ statuses, empty, extraCheck, children }) => {
  const status = statuses.includes('loading') || statuses.includes('idle') || extraCheck
    ? 'loading'
    : statuses.includes('failed')
      ? 'failed'
      : statuses.every((currStatus) => currStatus === 'succeeded') ? 'succeeded' : 'loading';
  return (
    <>
      {(status === 'loading') && (
        <Flex style={styles.boxStyle} justify={'center'} align={'center'}>
          <Spin size="large" />
        </Flex>
      )}
      {status === 'failed' && (
        <Flex style={styles.boxStyle} justify={'center'} align={'center'}>
          <Result
            status="error"
            title={intl.get('loading.failed.title')}
            subTitle={intl.get('loading.failed.subtitle')}
            extra={[
              <Button type="primary" key="console" onClick={() => {
                window.location.reload();
              }}>
                {intl.get('reload')}
              </Button>,
            ]}
          />
        </Flex>
      )}
      {(status === 'succeeded' && empty) && (
        <Flex style={styles.boxStyle} justify={'center'} align={'center'}>
          <Empty
            description={
              <span>
                {intl.get('empty.title')}
                <br />
                {intl.get('empty.subtitle')}
              </span>
            }
          />
        </Flex>
      )}
      {(status === 'succeeded' && !extraCheck && !empty) && (children)}
    </>
  );
}

export default BasePage;
