import React, { useEffect } from 'react';
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as customerActions, selectors as customerSelectors } from '../../store/customer';
import CustomerDetail from './components/CustomerDetail';
import { useParams } from 'react-router-dom';

const UpdateCustomerContainer: React.FC = () => {
  const params = useParams();
  const customerId = params.customerId ? Number.parseInt(params.customerId) : 0;

  const dispatch = useAppDispatch();
  const customer = useAppSelector(state => customerSelectors.selectCustomer(state, customerId));
  const status = useAppSelector(customerSelectors.selectCustomerStatus);
  const updateStatus = useAppSelector(customerSelectors.selectCustomerUpdateStatus);

  useEffect(() => {
    dispatch(customerActions.fetchCustomer({ customerId }));
  }, []);

  const handleUpdateCustomer = (updatedCustomer: Omit<Customer, 'id'>) => {
    console.log('handleUpdateCustomer', updatedCustomer)
    dispatch(customerActions.triggerUpdateCustomer({ ...updatedCustomer, id: customer.id }));
  }

  return (
    <CustomerDetail
      mode="update"
      customer={{ ...customer, birthDate: dayjs(status === 'succeeded' ? customer.birthDate : undefined) }}
      status={status}
      submitStatus={updateStatus}
      onSubmit={handleUpdateCustomer}
    />
  );
}

export default UpdateCustomerContainer;
