import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as contactActions, selectors as contactSelectors } from '../../store/contact';
import ContactDetail from './components/ContactDetail';
import { useParams } from 'react-router-dom';

const UpdateContactContainer: React.FC = () => {
  const params = useParams();
  const contactId = params.contactId ? Number.parseInt(params.contactId) : 0;
  const customerId = params.customerId ? Number.parseInt(params.customerId) : 0;

  const dispatch = useAppDispatch();
  const contact = useAppSelector(state => contactSelectors.selectContact(state, contactId));
  const status = useAppSelector(contactSelectors.selectContactStatus);
  const updateStatus = useAppSelector(contactSelectors.selectContactUpdateStatus);

  useEffect(() => {
    dispatch(contactActions.fetchContact({ contactId }));
  }, []);

  const handleUpdateContact = (updatedContact: Omit<Contact, 'id' | 'customer_id'>) => {
    console.log('handleUpdateContact', updatedContact)
    dispatch(contactActions.triggerUpdateContact({ ...updatedContact, id: contact.id, customer_id: customerId }));
  }

  return (
    <ContactDetail
      mode="update"
      customerId={customerId}
      contact={contact}
      status={status}
      submitStatus={updateStatus}
      onSubmit={handleUpdateContact}
    />
  );
}

export default UpdateContactContainer;
