export const styles: { [key: string]: React.CSSProperties } = {
  boxStyle: {
    width: '100%',
    height: '100%',
  },
  title: {
    marginTop: 0,
    marginBottom: '25px'
  },
  space: {
    display: 'flex'
  }
};

export default styles;