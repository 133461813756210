import React, { useEffect, useState } from 'react';
import ContactDetail from './ContactDetail';
import { CommonProps, FieldData } from './types';
import { parseContactToFieldData, parseFieldDataToContact } from './utils';

interface ContactDetailContainerProps extends CommonProps {
  contact: Contact;
  onSubmit: (contact: Contact) => void;
}

const ContactDetailContainer: React.FC<ContactDetailContainerProps> = (props) => {
  const { mode, contact, onSubmit } = props;
  const [readOnlyMode, setReadOnlyMode] = useState(mode === 'new' ? false : true);
  const [fields, setFields] = useState<FieldData[]>(parseContactToFieldData(contact));

  useEffect(() => {
    setFields(parseContactToFieldData(contact));
  }, [contact]);

  const handleSaveChanges = () => {
    onSubmit(parseFieldDataToContact(fields));
  }

  return (
    <ContactDetail
      {...props}
      readOnlyMode={readOnlyMode}
      setReadOnlyMode={setReadOnlyMode}
      fields={fields}
      setFields={setFields}
      onFinish={handleSaveChanges}
    />
  );
}

export default ContactDetailContainer;
