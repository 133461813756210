import React from 'react';
import { Button, Flex, Space, Typography, Form, Input, DatePicker, InputNumber, Select, List } from 'antd';
import intl from 'react-intl-universal';
import { Link, Navigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { NumericDictionary } from 'lodash';
import { TransferItem } from 'antd/es/transfer';
import SortableTransfer from '../../../../components/SortableTransfer';
import EntityText from '../../../../components/EntityText';
import DisplayDate from '../../../../components/DisplayDate';
import FormText from '../../../../components/FormText';
import FormArea from '../../../../components/FormArea';
import EditButton from '../../../../components/EditButton';
import BasePage from '../../../../components/BasePage';
import { CommonProps, FieldData } from './types';
import styles from './Styles';
import { PATHS, buildPath } from '../../../paths';

interface SessionDetailProps extends CommonProps {
  resourceData: NumericDictionary<Resource>;
  resourceStatus: Status;
  customerData: NumericDictionary<Customer>;
  customerStatus: Status;
  readOnlyMode: boolean;
  transferListDatasource: TransferItem[];
  fields: FieldData[];
  setFields: (fields: FieldData[]) => void;
  saveChanges: () => void;
  setReadOnlyMode: (value: boolean) => void;
}

const SessionDetail: React.FC<SessionDetailProps> = ({
  mode,
  status,
  submitStatus,
  resourceData,
  resourceStatus,
  customerData,
  customerStatus,
  readOnlyMode,
  transferListDatasource,
  fields,
  setFields,
  saveChanges,
  setReadOnlyMode,
}) => {

  return (
    <div>
      <Flex style={styles.titleContainer} justify={'left'} align={'center'}>
        <Link to={buildPath(PATHS.SESSIONS)}>
          <Button shape="circle" icon={<LeftOutlined />} />
        </Link>

        <Typography.Title level={3} style={styles.title}>
          {mode === 'new' ? intl.get('session.new.title') : intl.get('session.update.title')}
        </Typography.Title>
      </Flex>
      <BasePage statuses={[status, resourceStatus, customerStatus]} extraCheck={!fields.length}>
        <Space direction="vertical" size="middle" style={styles.space}>
          <Form
            fields={fields}
            onFieldsChange={(_, allFields) => {
              setFields(allFields);
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={saveChanges}
          >
            <Form.Item wrapperCol={{ offset: 14, span: 4 }}>
              <EditButton readOnlyMode={readOnlyMode} onToggle={() => setReadOnlyMode(!readOnlyMode)} />
            </Form.Item>
            <Form.Item label={intl.get('session.customer.label')} name={'customer_id'}>
              {readOnlyMode ? (
                <EntityText data={customerData} render={(entity) => (`${entity.name} ${entity.surname}`)} />
              ) : (
                <Select>
                  <Select.Option value={-1} disabled>
                    {intl.get('session.selectOption')}
                  </Select.Option>
                  {[...Object.values(customerData) as Array<Customer>].map((customer) => (
                    <Select.Option key={customer.id} value={customer.id}>
                      {`${customer.name} ${customer.surname}`}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={intl.get('session.date.label')} name={'date'}>
              {readOnlyMode ? (
                <DisplayDate showTime />
              ) : (
                <DatePicker showTime={{ format: 'HH:mm' }} />
              )}
            </Form.Item>
            <Form.Item label={intl.get('session.duration.label')} name={'duration'}>
              {readOnlyMode ? (
                <FormText suffix="min" />
              ) : (
                <InputNumber addonAfter="min" />
              )}
            </Form.Item>
            <Form.Item label={intl.get('session.price.label')} name={'price'}>
              {readOnlyMode ? (
                <FormText suffix="€" />
              ) : (
                <InputNumber addonAfter="€" />
              )}
            </Form.Item>
            <Form.Item label={intl.get('session.notes.label')} name={'notes'}>
              {readOnlyMode ? (
                <FormArea />
              ) : (
                <Input.TextArea rows={5} />
              )}
            </Form.Item>

            {readOnlyMode ? (
              <Form.Item label={intl.get('session.resources.label')} name={'resources'} valuePropName={'dataSource'}>
                <List
                  size="small"
                  bordered
                  renderItem={(item: string) => <List.Item>{resourceData[Number.parseInt(item)]?.name}</List.Item>}
                />
              </Form.Item>
            ) : (
              <Form.Item label={intl.get('session.resources.label')} name={'resources'} valuePropName={'targetKeys'}>
                <SortableTransfer dataSource={transferListDatasource} />
              </Form.Item>
            )}

            {!readOnlyMode && (
              <Form.Item wrapperCol={{ offset: 14, span: 4 }}>
                <Flex style={styles.boxStyle} justify={'right'} align={'center'} gap={"small"}>
                  <Link to={buildPath(PATHS.SESSIONS)}>
                    <Button>
                      {intl.get('cancel')}
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit" loading={submitStatus === 'loading'}>
                    {mode === 'new' ? intl.get('create') : intl.get('save')}
                  </Button>
                </Flex>
              </Form.Item>
            )}
          </Form>
        </Space >
      </BasePage>
      {submitStatus === 'succeeded' && (<Navigate to={buildPath(PATHS.SESSIONS)} />)}
    </div >
  );
}

export default SessionDetail;
