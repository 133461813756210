import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface ViewState {
  sessions: {
    currentCustomer?: number;
  }
}

const initialState: ViewState = {
  sessions: {},
}

export const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    setSessionsCurrentCustomer(state, action) {
      state.sessions.currentCustomer = action.payload;
    },
  },
})

const selectSessionsCurrentCustomer = (state: RootState) => state.view.sessions.currentCustomer

export const selectors = {
  selectSessionsCurrentCustomer
}

const { setSessionsCurrentCustomer } = viewSlice.actions
export const actions = {
  setSessionsCurrentCustomer
}

export default viewSlice.reducer