import { NumericDictionary, keyBy } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { addSession, deleteSession, getSession, getSessions, updateSession } from '../../api/session';

interface SessionState {
  data: NumericDictionary<Session>;
  status: Status;
  createStatus: Status;
  updateStatus: Status;
  error: string | null | undefined;
}

const initialState: SessionState = {
  data: {},
  status: 'idle',
  createStatus: 'idle',
  updateStatus: 'idle',
  error: null
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSessions.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.updateStatus = 'idle'
        state.createStatus = 'idle'
        state.data = keyBy(action.payload.sessions, 'id')
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchSession.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchSession.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const session = action.payload;
        state.data = { ...state.data, [session.id]: session };
      })
      .addCase(fetchSession.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(triggerCreateSession.pending, (state) => {
        state.createStatus = 'loading'
      })
      .addCase(triggerCreateSession.fulfilled, (state) => {
        state.createStatus = 'succeeded'
      })
      .addCase(triggerCreateSession.rejected, (state) => {
        state.createStatus = 'failed'
      })
      .addCase(triggerUpdateSession.pending, (state) => {
        state.updateStatus = 'loading'
      })
      .addCase(triggerUpdateSession.fulfilled, (state) => {
        state.updateStatus = 'succeeded'
      })
      .addCase(triggerUpdateSession.rejected, (state) => {
        state.updateStatus = 'failed'
      })
  }
})

const selectSessionData = (state: RootState) => state.session.data
const selectSession = (state: RootState, sessionId: number) => state.session.data[sessionId]
const selectSessionStatus = (state: RootState) => state.session.status
const selectSessionCreateStatus = (state: RootState) => state.session.createStatus
const selectSessionUpdateStatus = (state: RootState) => state.session.updateStatus
const selectSessionError = (state: RootState) => state.session.error

export const selectors = {
  selectSession,
  selectSessionData,
  selectSessionStatus,
  selectSessionCreateStatus,
  selectSessionUpdateStatus,
  selectSessionError
}

const fetchSessions = createAsyncThunk('getSessions', async () => await getSessions())
const fetchSession = createAsyncThunk('getSession', async ({ sessionId }: { sessionId: number }) => await getSession(sessionId))
const triggerUpdateSession = createAsyncThunk('updateSession',
  async ({ id, ...rest }: Session, { dispatch }) => {
    const response = await updateSession(id, rest)
    dispatch(fetchSessions())
    return response;
  }
)
const triggerCreateSession = createAsyncThunk('addSession',
  async (session: Omit<Session, 'id'>, { dispatch }) => {
    const response = await addSession(session)
    dispatch(fetchSessions())
    return response;
  }
)
const triggerDeleteSession = createAsyncThunk('deleteSession',
  async ({ sessionId }: { sessionId: number }, { dispatch }) => {
    const response = await deleteSession(sessionId)
    dispatch(fetchSessions())
    return response;
  }
)

export const actions = {
  fetchSession,
  fetchSessions,
  triggerUpdateSession,
  triggerCreateSession,
  triggerDeleteSession
}

export default sessionSlice.reducer