import React from 'react';
import { Button, Flex, Space, Typography, Form, Input, List } from 'antd';
import intl from 'react-intl-universal';
import { Link, Navigate } from 'react-router-dom';
import { NumericDictionary } from 'lodash';
import { LeftOutlined } from '@ant-design/icons';
import { DataNode } from 'antd/es/tree';
import TreeTransfer from '../../../../components/TreeTransfer';
import FormText from '../../../../components/FormText';
import FormArea from '../../../../components/FormArea';
import EditButton from '../../../../components/EditButton';
import BasePage from '../../../../components/BasePage';
import { CommonProps, FieldData } from './types';
import { getGoalIdFromStringKey } from './utils';
import styles from './Styles';
import { PATHS, buildPath } from '../../../paths';



interface ResourceDetailProps extends CommonProps {
  readOnlyMode: boolean;
  goalData: NumericDictionary<Goal>;
  goalStatus: Status;
  areaStatus: Status;
  transferListDatasource: DataNode[];
  fields: FieldData[];
  setFields: (fields: FieldData[]) => void;
  saveChanges: () => void;
  setReadOnlyMode: (value: boolean) => void;
}

const ResourceDetail: React.FC<ResourceDetailProps> = ({
  mode,
  status,
  submitStatus,
  readOnlyMode,
  goalData,
  goalStatus,
  areaStatus,
  transferListDatasource,
  fields,
  setFields,
  saveChanges,
  setReadOnlyMode
}) => {
  return (
    <div>
      <Flex style={styles.titleContainer} justify={'left'} align={'center'}>
        <Link to={buildPath(PATHS.RESOURCES)}>
          <Button shape="circle" icon={<LeftOutlined />} />
        </Link>

        <Typography.Title level={3} style={styles.title}>
          {mode === 'new' ? intl.get('resource.new.title') : intl.get('resource.update.title')}
        </Typography.Title>
      </Flex>
      <BasePage statuses={[status, goalStatus, areaStatus]} extraCheck={!fields.length}>
        <Space direction="vertical" size="middle" style={styles.space}>
          <Form
            fields={fields}
            onFieldsChange={(_, allFields) => {
              setFields(allFields);
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={saveChanges}
          >
            <Form.Item wrapperCol={{ offset: 14, span: 4 }}>
              <EditButton readOnlyMode={readOnlyMode} onToggle={() => setReadOnlyMode(!readOnlyMode)} />
            </Form.Item>
            <Form.Item label={intl.get('resource.name.label')} name={'name'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('resource.description.label')} name={'description'}>
              {readOnlyMode ? (<FormArea />) : (<Input.TextArea rows={4} />)}
            </Form.Item>
            {readOnlyMode ? (
              <Form.Item label={intl.get('resource.goals.label')} name={'goals'} valuePropName={'dataSource'}>
                <List
                  size="small"
                  bordered
                  renderItem={(item: string) => <List.Item>{goalData[getGoalIdFromStringKey(item)]?.name}</List.Item>}
                />
              </Form.Item>
            ) : (
              <Form.Item label={intl.get('resource.goals.label')} name={'goals'} valuePropName={'targetKeys'}>
                <TreeTransfer dataSource={transferListDatasource} />
              </Form.Item>
            )}
            {!readOnlyMode && (
              <Form.Item wrapperCol={{ offset: 14, span: 4 }}>
                <Flex style={styles.boxStyle} justify={'right'} align={'center'} gap={"small"}>
                  <Link to={buildPath(PATHS.RESOURCES)}>
                    <Button>
                      {intl.get('cancel')}
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit" loading={submitStatus === 'loading'}>
                    {mode === 'new' ? intl.get('create') : intl.get('save')}
                  </Button>
                </Flex>
              </Form.Item>
            )}
          </Form>
        </Space>
      </BasePage>
      {submitStatus === 'succeeded' && (<Navigate to={buildPath(PATHS.RESOURCES)} />)}
    </div >
  );
}

export default ResourceDetail;
