import React from 'react';
import { Button, Space, Typography, Table, Tag } from 'antd';
import styles from './Styles';
import intl from 'react-intl-universal';
import { PlusOutlined } from '@ant-design/icons';
import { sortByStringProperty } from '../../utils/sort';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import DeleteButton from '../../components/DeleteButton';
import BasePage from '../../components/BasePage';
import { NumericDictionary } from 'lodash';
import { PATHS, buildPath } from '../paths';

interface ResourcesProps {
  resourceData: NumericDictionary<Resource>;
  resourceStatus: Status;
  goalData: NumericDictionary<Goal>;
  goalStatus: Status;
  deleteResource: (resourceId: number) => void;
}

const Resources: React.FC<ResourcesProps> = ({ resourceData, resourceStatus, goalData, goalStatus, deleteResource }) => {
  const columns: ColumnsType<Resource> = [
    {
      title: intl.get('resources.column.resource'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={buildPath(PATHS.RESOURCE, { resourceId: record.id })}>{text}</Link>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterSearch: true,
      filters: Object.values(resourceData).map((resource) => ({
        text: resource.name,
        value: resource.name
      })),
      onFilter: (value: React.Key | boolean, record): boolean => (record.name.toUpperCase().indexOf(value.toString().toUpperCase()) > -1),
    },
    {
      title: intl.get('resources.column.description'),
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: intl.get('resources.column.goals'),
      key: 'goals',
      dataIndex: 'goals',
      render: (_, { goals }) => (
        <>
          {goals && goals.map((goalId) => goalData[goalId]).sort(sortByStringProperty('name')).map((goal) => (
            <Tag color='geekblue' key={goal.id} style={styles.tag}>
              {goal.name}
            </Tag>
          ))}
        </>
      ),
      filters: Object.values(goalData).map((goal) => ({
        text: goal.name,
        value: goal.id
      })),
      onFilter: (value: React.Key | boolean, record): boolean => (!!record.goals && record.goals.includes(value as number)),
    },
    {
      title: intl.get('resources.column.actions'),
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <DeleteButton
            title={intl.get('resources.deleteResource.title')}
            description={intl.get('resources.deleteResource.description')}
            onConfirm={() => deleteResource(record.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Typography.Title level={3} style={styles.title}>
        {intl.get('mainMenu.resources')}
      </Typography.Title>
      <BasePage statuses={[resourceStatus, goalStatus]}>
        <Space direction="vertical" size="middle" style={styles.space}>
          <Link to={buildPath(PATHS.NEW_RESOURCE)}>
            <Button type="primary" icon={<PlusOutlined />} >
              {intl.get('resources.newResource')}
            </Button>
          </Link>
          <Table columns={columns} dataSource={Object.values(resourceData).map((resource) => ({
            ...resource,
            key: resource.id,
          }))} />
        </Space>
      </BasePage>
    </div >
  );
}

export default Resources;
