import enUS from './locales/enUS.json';
import esES from './locales/esES.json';
import caES from './locales/caES.json';
import intl from 'react-intl-universal';

export const LOCALES_LIST = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Español",
    value: "es"
  },
  {
    label: "Català",
    value: "ca"
  }
];

const LOCALE_DATA = {
  "en-US": enUS,
  "en": enUS,
  "es-ES": esES,
  "es": esES,
  "ca-ES": caES,
  "ca": caES,
}

export const setCurrentLocale = (forceLocale?: string) => {
  let currentLocale: string;
  if (!forceLocale) {
    // 1. Get the currentLocale from url, cookie, or browser setting
    currentLocale = intl.determineLocale({
      localStorageLocaleKey: 'lang',
      urlLocaleKey: "lang",
      cookieLocaleKey: "lang"
    });
    // 2. Fallback to "en-US" if the currentLocale isn't supported in LOCALES_LIST
    if (!LOCALES_LIST.some(item => item.value === currentLocale)) {
      currentLocale = "en"
    }
  } else {
    currentLocale = forceLocale;
  }

  // 3. Set currentLocale and load locale data 
  intl.init({
    currentLocale,
    locales: LOCALE_DATA,
  });
};


