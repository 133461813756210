import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as resourceActions, selectors as resourceSelectors } from '../../store/resource';
import ResourceDetail from './components/ResourceDetail';

const NewResourceContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const createStatus = useAppSelector(resourceSelectors.selectResourceCreateStatus);

  const handleCreateResource = (newResource: Resource) => {
    console.log('handleCreateResource', newResource)
    dispatch(resourceActions.triggerCreateResource(newResource));
  }

  return (
    <ResourceDetail
      mode="new"
      resource={{
        id: -1,
        name: "",
        description: "",
        goals: [],
      }}
      status={'succeeded'}
      submitStatus={createStatus}
      onSubmit={handleCreateResource}
    />
  );
}

export default NewResourceContainer;
