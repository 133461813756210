export const styles: { [key: string]: React.CSSProperties } = {
  itemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  downward: {
    borderBottom: '2px dashed #1890ff',
  },
  upward: {
    borderTop: '2px dashed #1890ff',
  },
  label: {
    display: 'inline-block',
    maxWidth: 'calc(100% - 20px)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  handleGrab: {
    cursor: 'grab'
  },
  handleGrabbing: {
    cursor: 'grabbing'
  }
};

export default styles;