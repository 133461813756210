import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/typedHooks';
import { actions as contactActions, selectors as contactSelectors } from '../../../../store/contact';
import ContactsTable from './ContactsTable';
import { CommonProps } from './types';

interface ContactsTableContainerProps extends CommonProps {
  customerId: Customer['id'];
}

const ContactsTableContainer: React.FC<ContactsTableContainerProps> = ({ customerId, ...props }) => {
  const dispatch = useAppDispatch();
  const contactData = useAppSelector(contactSelectors.selectContactData);
  const contactStatus = useAppSelector(contactSelectors.selectContactStatus);

  useEffect(() => {
    dispatch(contactActions.fetchContacts());
  }, []);

  const handleDeleteContact = (contactId: number) => {
    dispatch(contactActions.triggerDeleteContact({ contactId, customerId }));
  }

  return (
    <ContactsTable
      {...props}
      customerId={customerId}
      contactData={contactData}
      contactStatus={contactStatus}
      deleteContact={handleDeleteContact}
    />
  );
}

export default ContactsTableContainer;
