export const styles: { [key: string]: React.CSSProperties } = {
  space: {
    display: 'flex'
  },
  paragraph: {
    padding: '0px',
    margin: '0px'
  },
  pre: {
    fontFamily: 'inherit',
    background: 'none',
    border: 'none',
    padding: '0px',
    margin: '0px'
  }
};

export default styles;