import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { App } from 'antd';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/typedHooks';
import { selectors as userSelectors, actions as userActions } from '../../../../store/user';
import { LOCALES_LIST } from '../../../../localization';
import UserMenu from './UserMenu';
import { PATHS, buildPath } from '../../../paths';

interface UserMenuContainerProps {
  isSessionValid: () => void;
}

const UserMenuContainer: React.FC<UserMenuContainerProps> = ({ isSessionValid }) => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.selectUserData);
  const userStatus = useAppSelector(userSelectors.selectUserStatus);
  const initials = userData?.name ? userData?.name.split(' ').map((word) => word[0]).join('').toUpperCase() : '-';

  useEffect(() => {
    dispatch(userActions.fetchUserInfo());
  }, []);

  const onLogOut = async () => {
    try {
      await Auth.signOut();
      await isSessionValid();
      navigate(buildPath(PATHS.LOGIN));
    } catch (e) {
      console.log(e);
      notification.error({
        message: intl.get('error'),
        description: intl.get('userMenu.signOutError'),
        placement: 'topRight',
      });
    }
  }

  const onLocaleChange = (value: string) => {
    localStorage.setItem("lang", value);
    window.location.reload();
  }

  const langItems = LOCALES_LIST.map(({ label, value }) => ({ key: `lang/${value}`, label }));
  const langKeys = langItems.map(({ key }) => key);

  const onMenuItemClick = ({ key }: { key: string }) => {
    if (langKeys.includes(key)) {
      const lang = key.split('/')[1];
      onLocaleChange(lang);
    } else {
      switch (key) {
        case 'logout':
          onLogOut();
          break;
        default:
          break;
      }
    }
  }

  return (
    <UserMenu
      userData={userData}
      userStatus={userStatus}
      initials={initials}
      langugageItems={langItems}
      onMenuItemClick={onMenuItemClick}
    />
  );
}

export default UserMenuContainer;
