import React from 'react';
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as sessionActions, selectors as sessionSelectors } from '../../store/session';
import SessionDetail from './components/SessionDetail';

const NewSessionContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const createStatus = useAppSelector(sessionSelectors.selectSessionCreateStatus);

  const handleCreateSession = (newSession: Session) => {
    console.log('handleCreateSession', newSession)
    dispatch(sessionActions.triggerCreateSession(newSession));
  }

  return (
    <SessionDetail
      mode="new"
      session={{
        id: -1,
        date: dayjs().second(0),
        duration: 0,
        price: 0,
        notes: "",
        customer_id: -1,
        resources: [],
      }}
      status={'succeeded'}
      submitStatus={createStatus}
      onSubmit={handleCreateSession}
    />
  );
}

export default NewSessionContainer;
