import React, { ReactNode } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

type SuffixRenderer = (value: string) => ReactNode;

interface FormTextProps {
  value?: string;
  suffix?: string | SuffixRenderer;
}

const FormText: React.FC<FormTextProps> = ({ value = '', suffix }) => {
  return (
    <Text>{value}{suffix ? typeof suffix === 'function' ? suffix(value) : ` ${suffix}` : ''}</Text>
  );
}

export default FormText;
