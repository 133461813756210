import React from 'react';
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as customerActions, selectors as customerSelectors } from '../../store/customer';
import CustomerDetail from './components/CustomerDetail';

const NewCustomerContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const createStatus = useAppSelector(customerSelectors.selectCustomerCreateStatus);

  const handleCreateCustomer = (newCustomer: Customer) => {
    console.log('handleCreateCustomer', newCustomer)
    dispatch(customerActions.triggerCreateCustomer(newCustomer));
  }

  return (
    <CustomerDetail
      mode="new"
      customer={{
        id: -1,
        name: "",
        surname: "",
        birthDate: dayjs(),
        schoolGrade: "",
        address: "",
        postalCode: 0,
        city: "",
        notes: "",
        contacts: [],
      }}
      status={'succeeded'}
      submitStatus={createStatus}
      onSubmit={handleCreateCustomer}
    />
  );
}

export default NewCustomerContainer;
