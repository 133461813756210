import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as customerActions, selectors as customerSelectors } from '../../store/customer';
import Customers from './Customers';

const CustomersContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const customerData = useAppSelector(customerSelectors.selectCustomerData);
  const customerStatus = useAppSelector(customerSelectors.selectCustomerStatus);

  useEffect(() => {
    dispatch(customerActions.fetchCustomers());
  }, []);

  const handleDeleteCustomer = (customerId: number) => {
    dispatch(customerActions.triggerDeleteCustomer({ customerId }));
  }

  return (
    <Customers
      customerData={customerData}
      customerStatus={customerStatus}
      deleteCustomer={handleDeleteCustomer}
    />
  );
}

export default CustomersContainer;
