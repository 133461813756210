import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as resourceActions, selectors as resourceSelectors } from '../../store/resource';
import ResourceDetail from './components/ResourceDetail';
import { useParams } from 'react-router-dom';

const UpdateResourceContainer: React.FC = () => {
  const params = useParams();
  const resourceId = params.resourceId ? Number.parseInt(params.resourceId) : 0;

  const dispatch = useAppDispatch();
  const resource = useAppSelector(state => resourceSelectors.selectResource(state, resourceId));
  const status = useAppSelector(resourceSelectors.selectResourceStatus);
  const updateStatus = useAppSelector(resourceSelectors.selectResourceUpdateStatus);

  useEffect(() => {
    dispatch(resourceActions.fetchResource({ resourceId }));
  }, []);

  const handleUpdateResource = (updatedResource: Omit<Resource, 'id'>) => {
    console.log('handleUpdateResource', updatedResource)
    dispatch(resourceActions.triggerUpdateResource({ ...updatedResource, id: resource.id }));
  }

  return (
    <ResourceDetail
      mode="update"
      resource={resource}
      status={status}
      submitStatus={updateStatus}
      onSubmit={handleUpdateResource}
    />
  );
}

export default UpdateResourceContainer;
