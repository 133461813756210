import { NumericDictionary, keyBy } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { addGoal, deleteGoal, getGoals, updateGoal } from '../../api/goal';
import { actions as areaActions } from '../area'

interface GoalState {
  data: NumericDictionary<Goal>;
  status: Status;
  createStatus: Status;
  error: string | null | undefined;
}

const initialState: GoalState = {
  data: {},
  status: 'idle',
  createStatus: 'idle',
  error: null
}

export const goalSlice = createSlice({
  name: 'goal',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGoals.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchGoals.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.createStatus = 'idle'
        state.data = keyBy(action.payload.goals, 'id')
      })
      .addCase(fetchGoals.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(triggerCreateGoal.pending, (state) => {
        state.createStatus = 'loading'
      })
      .addCase(triggerCreateGoal.fulfilled, (state) => {
        state.createStatus = 'succeeded'
      })
      .addCase(triggerCreateGoal.rejected, (state) => {
        state.createStatus = 'failed'
      })
  }
})

const selectGoalData = (state: RootState) => state.goal.data
const selectGoalStatus = (state: RootState) => state.goal.status
const selectGoalCreateStatus = (state: RootState) => state.goal.createStatus
const selectGoalError = (state: RootState) => state.goal.error

export const selectors = {
  selectGoalData,
  selectGoalStatus,
  selectGoalCreateStatus,
  selectGoalError
}

const fetchGoals = createAsyncThunk('getGoals', async () => await getGoals())
const triggerUpdateGoal = createAsyncThunk('updateGoal',
  async ({ goalId, name }: { goalId: number, name: string }, { dispatch }) => {
    const response = await updateGoal(goalId, name)
    dispatch(fetchGoals())
    return response;
  }
)
const triggerCreateGoal = createAsyncThunk('addGoal',
  async ({ name, areaId }: { name: string, areaId: number }, { dispatch }) => {
    const response = await addGoal(name, areaId)
    dispatch(fetchGoals())
    dispatch(areaActions.fetchArea({ areaId }))
    return response;
  }
)
const triggerDeleteGoal = createAsyncThunk('deleteGoal',
  async ({ goalId, areaId }: { goalId: number, areaId: number }, { dispatch }) => {
    const response = await deleteGoal(goalId)
    dispatch(areaActions.fetchArea({ areaId }))
    dispatch(fetchGoals())
    return response;
  }
)

export const actions = {
  fetchGoals,
  triggerUpdateGoal,
  triggerCreateGoal,
  triggerDeleteGoal
}

export default goalSlice.reducer