import React from 'react';
import { Button, Space, Typography, Table } from 'antd';
import styles from './Styles';
import intl from 'react-intl-universal';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import DisplayDate from '../../components/DisplayDate';
import DeleteButton from '../../components/DeleteButton';
import BasePage from '../../components/BasePage';
import { NumericDictionary } from 'lodash';
import { PATHS, buildPath } from '../paths';

interface CustomersProps {
  customerData: NumericDictionary<Customer>;
  customerStatus: Status;
  deleteCustomer: (id: number) => void;
}

const Customers: React.FC<CustomersProps> = ({ customerData, customerStatus, deleteCustomer }) => {
  const columns: ColumnsType<Customer> = [
    {
      title: intl.get('customers.column.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={buildPath(PATHS.CUSTOMER, { customerId: record.id })}>{`${record.name} ${record.surname}`}</Link>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterSearch: true,
      filters: Object.values(customerData).map((customer) => ({
        text: `${customer.name} ${customer.surname}`,
        value: `${customer.name} ${customer.surname}`
      })),
      onFilter: (value: React.Key | boolean, record): boolean => (`${record.name} ${record.surname}`.toUpperCase().indexOf(value.toString().toUpperCase()) > -1),
    },
    {
      title: intl.get('customers.column.birthDate'),
      dataIndex: 'birthDate',
      key: 'birthDate',
      sorter: (a, b) => dayjs(a.birthDate).diff(dayjs(b.birthDate)),
      render: (_, record) => <DisplayDate value={record.birthDate} showDate />,
    },
    {
      title: intl.get('customers.column.actions'),
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <DeleteButton
            title={intl.get('customers.deleteCustomer.title')}
            description={intl.get('customers.deleteCustomer.description')}
            onConfirm={() => deleteCustomer(record.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Typography.Title level={3} style={styles.title}>
        {intl.get('mainMenu.customers')}
      </Typography.Title>
      <BasePage statuses={[customerStatus]}>
        <Space direction="vertical" size="middle" style={styles.space}>
          <Link to={buildPath(PATHS.NEW_CUSTOMER)}>
            <Button type="primary" icon={<PlusOutlined />} >
              {intl.get('customers.newCustomer')}
            </Button>
          </Link>
          <Table columns={columns} dataSource={Object.values(customerData).map((customer) => ({
            ...customer,
            key: customer.id,
          }))} />
        </Space>
      </BasePage>
    </div >
  );
}

export default Customers;
