import { GlobalToken } from "antd";

const getStyles = (token: GlobalToken): { [key: string]: React.CSSProperties } => ({
  contentStyle: {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  },
  name: {
    padding: '15px'
  },
  email: {
    padding: '15px',
    paddingTop: 0,
    fontSize: '12px'
  },
  divider: {
    margin: 0
  },
  menuStyle: {
    boxShadow: 'none',
  },
  avatar:{
    backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer'
  }
});

export default getStyles;