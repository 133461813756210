import { CustomerWithDate, FieldData } from "./types";

export const parseCustomerToFieldData = (customer: CustomerWithDate): FieldData[] => {
  if (!customer) return [];
  return (Object.keys(customer) as Array<keyof Customer>).map((customerKey) => ({
    name: customerKey,
    value: customer[customerKey],
  }))
}

export const parseFieldDataToCustomer = (fields: FieldData[]): Customer => {
  const customerWithDate = (fields.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.value }), {})) as CustomerWithDate;
  return {
    ...customerWithDate,
    birthDate: customerWithDate.birthDate.toISOString(),
  }
}