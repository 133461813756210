/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { ReactNode } from 'react';
import { Typography } from 'antd';
import { NumericDictionary } from 'lodash';

const { Text } = Typography;

interface EntityTextProps<T> {
  value?: number;
  data: NumericDictionary<T>;
  render: (entity: T) => ReactNode;
}

type EntityTextI<T = any> = React.FC<EntityTextProps<T>>


const EntityText: EntityTextI = ({ data, value = -1, render }) => {
  return (
    <Text>{data[value] && render(data[value])}</Text>
  );
}

export default EntityText;
