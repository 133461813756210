import React, { useEffect, useState } from 'react';
import { actions as resourceActions, selectors as resourceSelectors } from '../../../../store/resource';
import { actions as customerActions, selectors as customerSelectors } from '../../../../store/customer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/typedHooks';
import { TransferItem } from 'antd/es/transfer';
import { parseFieldDataToSession, parseSessionToFieldData } from './utils';
import { CommonProps, FieldData, SessionWithDate } from './types';
import SessionDetail from './SessionDetail';

interface SessionDetailContainerProps extends CommonProps {
  session: SessionWithDate;
  onSubmit: (session: Session) => void;
}

const SessionDetailContainer: React.FC<SessionDetailContainerProps> = ({ mode, session, status, submitStatus, onSubmit }) => {
  const [readOnlyMode, setReadOnlyMode] = useState(mode === 'new' ? false : true);
  const [fields, setFields] = useState<FieldData[]>(parseSessionToFieldData(session));
  const [transferListDatasource, setTransferListDatasource] = useState<TransferItem[]>([])

  const dispatch = useAppDispatch();
  const customerData = useAppSelector(customerSelectors.selectCustomerData);
  const customerStatus = useAppSelector(customerSelectors.selectCustomerStatus);
  const resourceData = useAppSelector(resourceSelectors.selectResourceData);
  const resourceStatus = useAppSelector(resourceSelectors.selectResourceStatus);

  useEffect(() => {
    dispatch(customerActions.fetchCustomers());
    dispatch(resourceActions.fetchResources());
  }, []);

  useEffect(() => {
    setFields(parseSessionToFieldData(session));
  }, [session]);

  useEffect(() => {
    if (resourceData && Object.keys(resourceData).length) {
      setTransferListDatasource([...Object.values(resourceData) as Array<Resource>].map((resource) => ({
        key: resource.id + '', title: resource.name
      })));
    }
  }, [resourceData]);

  const handleSaveChanges = () => {
    onSubmit(parseFieldDataToSession(fields));
  }

  return (
    <SessionDetail
      mode={mode}
      status={status}
      submitStatus={submitStatus}
      resourceData={resourceData}
      resourceStatus={resourceStatus}
      customerData={customerData}
      customerStatus={customerStatus}
      readOnlyMode={readOnlyMode}
      transferListDatasource={transferListDatasource}
      fields={fields}
      setFields={setFields}
      saveChanges={handleSaveChanges}
      setReadOnlyMode={setReadOnlyMode}
    />
  );
}

export default SessionDetailContainer;
