import React from 'react';
import { Button, Flex, Space, Typography, Form, Input, DatePicker } from 'antd';
import styles from './Styles';
import intl from 'react-intl-universal';
import { Link, Navigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import ContactsTable from '../ContactsTable';
import FormText from '../../../../components/FormText';
import FormArea from '../../../../components/FormArea';
import DisplayDate from '../../../../components/DisplayDate';
import EditButton from '../../../../components/EditButton';
import BasePage from '../../../../components/BasePage';
import { CommonProps, FieldData } from './types';
import { PATHS, buildPath } from '../../../paths';

interface CustomerDetailProps extends CommonProps {
  readOnlyMode: boolean;
  fields: FieldData[];
  setReadOnlyMode: (value: boolean) => void;
  setFields: (fields: FieldData[]) => void;
  saveChanges: () => void;
}

const CustomerDetail: React.FC<CustomerDetailProps> = ({
  mode,
  customer,
  status,
  submitStatus,
  readOnlyMode,
  fields,
  setReadOnlyMode,
  setFields,
  saveChanges
}) => {

  return (
    <div>
      <Flex style={styles.titleContainer} justify={'left'} align={'center'}>
        <Link to={buildPath(PATHS.CUSTOMERS)}>
          <Button shape="circle" icon={<LeftOutlined />} />
        </Link>

        <Typography.Title level={3} style={styles.title}>
          {mode === 'new' ? intl.get('customer.new.title') : intl.get('customer.update.title')}
        </Typography.Title>
      </Flex>
      <BasePage statuses={[status]} extraCheck={!fields.length}>
        <Space direction="vertical" size="middle" style={styles.space}>
          <Form
            fields={fields}
            onFieldsChange={(_, allFields) => {
              setFields(allFields);
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            onFinish={saveChanges}
          >
            <Form.Item wrapperCol={{ offset: 14, span: 4 }}>
              <EditButton readOnlyMode={readOnlyMode} onToggle={() => setReadOnlyMode(!readOnlyMode)} />
            </Form.Item>
            <Form.Item label={intl.get('customer.name.label')} name={'name'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('customer.surname.label')} name={'surname'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('customer.birthDate.label')} name={'birthDate'}>
              {readOnlyMode ? (<DisplayDate showDate />) : (<DatePicker />)}

            </Form.Item>
            <Form.Item label={intl.get('customer.schoolGrade.label')} name={'schoolGrade'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('customer.address.label')} name={'address'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('customer.postalCode.label')} name={'postalCode'}>
              {readOnlyMode ? (<FormText />) : (<Input type='number' />)}
            </Form.Item>
            <Form.Item label={intl.get('customer.city.label')} name={'city'}>
              {readOnlyMode ? (<FormText />) : (<Input />)}
            </Form.Item>
            <Form.Item label={intl.get('customer.notes.label')} name={'notes'}>
              {readOnlyMode ? (<FormArea />) : (<Input.TextArea rows={5} />)}
            </Form.Item>
            {mode !== 'new' && (
              <Form.Item label={intl.get('customer.contacts.label')} name={'contacts'}>
                <ContactsTable customerId={customer.id} contacts={customer.contacts} readonly={readOnlyMode} />
              </Form.Item>
            )}

            {!readOnlyMode && (
              <Form.Item wrapperCol={{ offset: 14, span: 4 }}>
                <Flex style={styles.boxStyle} justify={'right'} align={'center'} gap={"small"}>
                  <Link to={buildPath(PATHS.CUSTOMERS)}>
                    <Button>
                      {intl.get('cancel')}
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit" loading={submitStatus === 'loading'}>
                    {mode === 'new' ? intl.get('create') : intl.get('save')}
                  </Button>
                </Flex>
              </Form.Item>
            )}
          </Form>
        </Space>
      </BasePage>
      {submitStatus === 'succeeded' && (<Navigate to={buildPath(PATHS.CUSTOMERS)} />)}
    </div >
  );
}

export default CustomerDetail;
