import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/typedHooks';
import { actions as goalActions, selectors as goalSelectors } from '../../../../store/goal';
import { actions as areaActions, selectors as areaSelectors } from '../../../../store/area';
import { CommonProps, FieldData } from './types';
import { getDatasource, parseFieldDataToResource, parseResourceToFieldData } from './utils';
import ResourceDetail from './ResourceDetail';


interface ResourceDetailProps extends CommonProps {
  resource: Resource;
  onSubmit: (session: Resource) => void;
}

const ResourceDetailContainer: React.FC<ResourceDetailProps> = ({ mode, resource, status, submitStatus, onSubmit }) => {
  const dispatch = useAppDispatch();
  const areaData = useAppSelector(areaSelectors.selectAreaData);
  const areaStatus = useAppSelector(areaSelectors.selectAreaStatus);
  const goalData = useAppSelector(goalSelectors.selectGoalData);
  const goalStatus = useAppSelector(goalSelectors.selectGoalStatus);
  const [readOnlyMode, setReadOnlyMode] = useState(mode === 'new' ? false : true);
  const [fields, setFields] = useState<FieldData[]>(parseResourceToFieldData(resource));
  const [transferListDatasource, setTransferListDatasource] = useState(getDatasource(areaData, goalData))

  useEffect(() => {
    dispatch(areaActions.fetchAreas());
    dispatch(goalActions.fetchGoals());
  }, []);

  useEffect(() => {
    setFields(parseResourceToFieldData(resource));
  }, [resource]);

  useEffect(() => {
    setTransferListDatasource(getDatasource(areaData, goalData));
  }, [areaData, goalData]);

  const handleSaveChanges = () => {
    onSubmit(parseFieldDataToResource(fields));
  }

  return (
    <ResourceDetail
      mode={mode}
      status={status}
      submitStatus={submitStatus}
      readOnlyMode={readOnlyMode}
      goalData={goalData}
      goalStatus={goalStatus}
      areaStatus={areaStatus}
      transferListDatasource={transferListDatasource}
      fields={fields}
      setFields={setFields}
      saveChanges={handleSaveChanges}
      setReadOnlyMode={setReadOnlyMode}
    />
  );
}

export default ResourceDetailContainer;
