import { API } from "aws-amplify";
import config from "../config";

const API_NAME = config.apiGateway[0].name;

export const getCustomers = () => {
  const path = 'customers';
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const getCustomer = (customerId: number) => {
  const path = `customers/${customerId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const addCustomer = (customer: Omit<Customer, 'id'>) => {
  const path = 'customers';
  const myInit = {
    body: customer,
    headers: {},
    response: false,
  };

  return API.put(API_NAME, path, myInit);
}

export const updateCustomer = ({ id, ...customer }: Customer) => {
  const path = `customers/${id}`;
  const myInit = {
    body: customer,
    headers: {},
    response: false,
  };

  return API.post(API_NAME, path, myInit);
}

export const deleteCustomer = (customerId: number) => {
  const path = `customers/${customerId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.del(API_NAME, path, myInit);
}

