import React from 'react';
import { MailOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { NumericDictionary } from 'lodash';
import DeleteButton from '../../../../components/DeleteButton';
import BasePage from '../../../../components/BasePage';
import styles from './Styles';
import { CommonProps } from './types';
import { PATHS, buildPath } from '../../../paths';

interface ContactsTableProps extends CommonProps {
  contactData: NumericDictionary<Contact>;
  contactStatus: Status;
  deleteContact: (id: number) => void;
}

const ContactsTable: React.FC<ContactsTableProps> = ({ customerId, contacts, readonly, contactData, contactStatus, deleteContact }) => {
  const columns: ColumnsType<Contact> = [
    {
      title: intl.get('contacts.column.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={buildPath(PATHS.CONTACT, { customerId, contactId: record.id })}>{`${record.name} ${record.surname}`}</Link>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterSearch: true,
      filters: Object.values(contactData).map((customer) => ({
        text: `${customer.name} ${customer.surname}`,
        value: `${customer.name} ${customer.surname}`
      })),
      onFilter: (value: React.Key | boolean, record): boolean => (`${record.name} ${record.surname}`.toUpperCase().indexOf(value.toString().toUpperCase()) > -1),
    },
    {
      title: intl.get('contacts.column.persona'),
      dataIndex: 'persona',
      key: 'persona',
    },
    {
      title: intl.get('contacts.column.phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: intl.get('contacts.column.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: intl.get('contacts.column.actions'),
      key: 'action',
      render: (_: string, record: Contact) => (
        <Space size="middle">
          <Tooltip title={intl.get('contacts.sendEmail.title')}>
            <Typography.Link
              href={`mailto:${record.email}`}
              rel="noopener noreferrer"
            >
              <MailOutlined />
            </Typography.Link>
          </Tooltip>
          {!readonly && (
            <DeleteButton
              title={intl.get('contacts.deleteContact.title')}
              description={intl.get('contacts.deleteContact.description')}
              onConfirm={() => deleteContact(record.id)}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <BasePage statuses={[contactStatus]}>
      <Space direction="vertical" size="middle" style={styles.space}>
        <Table
          columns={columns}
          dataSource={
            Object
              .values(contactData)
              .filter((contact) => contacts.includes(contact.id))
              .map((contact) => ({ ...contact, key: contact.id }))
          }
          size="small"
          footer={readonly ? undefined : () => (
            <Link to={buildPath(PATHS.NEW_CONTACT,{customerId})}>
              <Button type="primary" icon={<PlusOutlined />} >
                {intl.get('contacts.newContact')}
              </Button>
            </Link>
          )}
        />
      </Space >
    </BasePage>
  );
}

export default ContactsTable;
