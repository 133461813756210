import React from 'react';
import { Button, Flex, } from 'antd';
import intl from 'react-intl-universal';

interface EditButtonProps {
  readOnlyMode: boolean;
  onToggle: () => void;
}

const EditButton: React.FC<EditButtonProps> = ({ readOnlyMode, onToggle }) => {
  return readOnlyMode ? (
    <Flex justify={'right'}>
      <Button type='primary' onClick={onToggle}>
        {readOnlyMode ? intl.get('edit') : intl.get('close')}
      </Button>
    </Flex>
  ) : <></>;
}

export default EditButton;
