import { API } from "aws-amplify";
import config from "../config";

const API_NAME = config.apiGateway[0].name;

export const getSessions = () => {
  const path = 'sessions';
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const getSession = (sessionId: number) => {
  const path = `sessions/${sessionId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const addSession = (session: Omit<Session, 'id'>) => {
  const path = 'sessions';
  const myInit = {
    body: session,
    headers: {},
    response: false,
  };

  return API.put(API_NAME, path, myInit);
}

export const updateSession = (sessionId: number, updatedSession: Omit<Session, 'id'>) => {
  const path = `sessions/${sessionId}`;
  const myInit = {
    body: updatedSession,
    headers: {},
    response: false,
  };

  return API.post(API_NAME, path, myInit);
}

export const deleteSession = (sessionId: number) => {
  const path = `sessions/${sessionId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.del(API_NAME, path, myInit);
}

