const styles: { [key: string]: React.CSSProperties } = {
  boxStyle: {
    width: '100%',
    height: '100%',
    backgroundColor: '#f3debe'
  },
  card: {
    padding: 0,
    overflow: 'hidden',
    border: '3px'
  },
  cardBody: {
    padding: 0,
  },
  rightContainer: {
    padding: '24px'
  },
  formContainer: {
    width: '100%',
    height: '100%',
  },
  form: {
    width: 430,
    maxWidth: 'calc(100vw - 48px)'
  },
  titleItem:{
    textAlign: 'center' 
  },
  title: {
    marginTop: 0,
  }
};

export default styles;

