import { FieldData, SessionWithDate, SessionWithDateWithoutOrder } from "./types";

export const parseSessionToFieldData = (session: SessionWithDate): FieldData[] => {
  if (!session) return [];
  return (Object.keys(session) as Array<keyof Session>).map((sessionKey) => (
    sessionKey !== 'resources' ?
      {
        name: sessionKey,
        value: session[sessionKey],
      }
      :
      {
        name: sessionKey,
        value: [...session[sessionKey]]
          .sort((a, b) => a.position - b.position)
          .map(({ resource_id }) => resource_id + ''),
      }
  ))
}

export const parseFieldDataToSession = (fields: FieldData[]): Session => {
  const sessionWithDate = (fields.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.value }), {})) as SessionWithDateWithoutOrder;
  return {
    ...sessionWithDate,
    date: sessionWithDate.date.toISOString(),
    resources: sessionWithDate.resources.map((resource_id, position): OrderedResource => ({ resource_id: Number.parseInt(resource_id), position }))
  }
}