import React from 'react';
import { Button, Collapse, Flex, List, Space, Typography } from 'antd';
import styles from './Styles';
import intl from 'react-intl-universal';
import EditableText from './components/EditableText';
import { PlusOutlined } from '@ant-design/icons';
import { sortByStringProperty } from '../../utils/sort';
import DeleteButton from '../../components/DeleteButton';
import BasePage from '../../components/BasePage';
import { NumericDictionary } from 'lodash';

interface GoalsProps {
  areaData: NumericDictionary<Area>;
  areaStatus: Status;
  areaCreateStatus: Status;
  goalData: NumericDictionary<Goal>;
  goalStatus: Status;
  goalCreateStatus: Status;
  createArea: () => void;
  updateArea: (areaId: number, name: string) => void;
  deleteArea: (areaId: number) => void;
  createGoal: (areaId: number) => void;
  updateGoal: (goalId: number, name: string) => void;
  deleteGoal: (goalId: number, areaId: number) => void;
}

const Goals: React.FC<GoalsProps> = ({
  areaData,
  areaStatus,
  areaCreateStatus,
  goalData,
  goalStatus,
  goalCreateStatus,
  createArea,
  updateArea,
  deleteArea,
  createGoal,
  updateGoal,
  deleteGoal,
}) => {
  return (
    <div>
      <Typography.Title level={3} style={styles.title}>
        {intl.get('mainMenu.goals')}
      </Typography.Title>

      <Space direction="vertical" size="middle" style={styles.space}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={createArea}
          loading={areaCreateStatus === 'loading'}
        >
          {intl.get('goals.newArea')}
        </Button>

        <BasePage statuses={[areaStatus, goalStatus]} empty={!Object.keys(areaData).length}>
          <Collapse>
            {(Object.values(areaData) as Array<Area>).sort(sortByStringProperty<Area>('name')).map((area) => (
              <Collapse.Panel
                key={area.id}
                header={(
                  <EditableText originalValue={area.name} onEditEnd={(value) => updateArea(area.id, value)} />
                )}
                extra={(
                  <DeleteButton
                    title={intl.get('goals.deleteArea.title')}
                    description={intl.get('goals.deleteArea.description')}
                    onConfirm={() => deleteArea(area.id)}
                  />
                )}
              >
                <List
                  header={(
                    <Flex style={styles.boxStyle} justify={'space-between'} align={'center'}>
                      <b>{intl.get('goals.Goals')}</b>
                      <Button
                        type="primary"
                        shape="circle"
                        onClick={() => createGoal(area.id)}
                        loading={goalCreateStatus === 'loading'}
                      >
                        <PlusOutlined />
                      </Button>
                    </Flex>
                  )}
                  bordered
                  dataSource={area.goals
                    .map((goalId) => goalData[goalId])
                    .filter((goal) => !!goal)
                    .sort(sortByStringProperty<Goal>('name'))
                  }
                  renderItem={(item) => (
                    <List.Item>
                      <Flex style={styles.boxStyle} justify={'space-between'} align={'center'}>
                        <Typography.Text editable={{ onChange: (value) => updateGoal(item.id, value) }}>
                          {item.name}
                        </Typography.Text>
                        <DeleteButton
                          title={intl.get('goals.deleteGoal.title')}
                          description={intl.get('goals.deleteGoal.description')}
                          onConfirm={() => deleteGoal(item.id, area.id)}
                        />
                      </Flex>
                    </List.Item>
                  )}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </BasePage>
      </Space>
    </div >
  );
}

export default Goals;
