import React, { ChangeEvent, useState } from 'react';
import { App } from 'antd';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import intl from 'react-intl-universal';
import PasswordCreator from './PasswordCreator';
import { PasswordFields, ValidatorState } from './types';
import { PATHS, buildPath } from '../../../paths';

interface PasswordCreatorContainerProps {
  cognitoUser: CognitoUser;
  isSessionValid: () => void;
}

const PasswordCreatorContainer: React.FC<PasswordCreatorContainerProps> = ({ cognitoUser, isSessionValid }) => {
  const { notification } = App.useApp();
  const [fields, setFields] = useState<PasswordFields>({ password1: '', password2: '' });
  const [loading, setLoading] = useState(false);
  const [passError, setPassError] = useState<boolean>(true);
  const [validator, setValidator] = useState<ValidatorState>({
    passLength: true,
    upper: true,
    lower: true,
    number: true,
    special: true,
    passEquals: true
  });
  const navigate = useNavigate();

  const handlePasswordInputChange = (name: keyof PasswordFields, evt: ChangeEvent<HTMLInputElement>) => {
    const passwords = {
      password1: fields.password1,
      password2: fields.password2
    };
    passwords[name] = evt.target.value;

    const validator = validatePass(passwords.password1, passwords.password2);
    const noError = (!validator.passEquals && !validator.lower
      && !validator.upper && !validator.number && !validator.special && !validator.passLength);

    setPassError(!noError);
    setValidator(validator);
    setFields((prevState) => ({ ...prevState, [name]: evt.target.value }))
  }

  const validatePass = (value1: string, value2: string) => {
    const lower = (/[a-z]/.test(value1));
    const upper = (/[A-Z]/.test(value1));
    const number = (/[0-9]/.test(value1));
    const specialChar = (/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/.test(value1));
    const numchars = (value1.length >= 8);

    validator.lower = !lower;
    validator.upper = !upper;
    validator.number = !number;
    validator.special = !specialChar;
    validator.passLength = !numchars;
    if (value1 !== '' && value1 === value2) {
      validator.passEquals = false;
    } else {
      validator.passEquals = true;
    }

    return validator;
  }

  const onChangePassword = async () => {
    setLoading(true);
    try {
      const response = await Auth.completeNewPassword(cognitoUser, fields.password1)
      console.log('response', response)
      await isSessionValid();
      navigate(buildPath(PATHS.ROOT));
    } catch (e) {
      notification.error({
        message: intl.get('error'),
        description: intl.get('password.changePasswordError'),
        placement: 'topRight',
      });
      console.log(e);
      setLoading(false);
    }
  }

  return (
    <PasswordCreator
      validator={validator}
      fields={fields}
      passError={passError}
      loading={loading}
      passwordInputChange={handlePasswordInputChange}
      onChangePassword={onChangePassword}
    />
  );
}

export default PasswordCreatorContainer;