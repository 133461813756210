import React from 'react';
import { Popconfirm, PopconfirmProps } from 'antd';
import intl from 'react-intl-universal';
import { DeleteOutlined } from '@ant-design/icons';

interface DeleteButtonProps {
  title: string;
  description: string;
  onConfirm: PopconfirmProps['onConfirm'];
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ title, description, onConfirm }) => {
  return (
    <Popconfirm
      title={title}
      description={description}
      okText={intl.get('yes')}
      cancelText={intl.get('no')}
      onConfirm={onConfirm}
    >
      <DeleteOutlined />
    </Popconfirm>
  );
}

export default DeleteButton;
