import { API } from "aws-amplify";
import config from "../config";

// TODO: extract endpoints into one file???

const API_NAME = config.apiGateway[0].name;

export const getAreas = () => {
  const path = 'areas';
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const getArea = (areaId: number) => {
  const path = `areas/${areaId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.get(API_NAME, path, myInit);
}

export const addArea = (name: string) => {
  const path = 'areas';
  const myInit = {
    body: { name },
    headers: {},
    response: false,
  };

  return API.put(API_NAME, path, myInit);
}

export const updateArea = (areaId: number, name: string) => {
  const path = `areas/${areaId}`;
  const myInit = {
    body: { name },
    headers: {},
    response: false,
  };

  return API.post(API_NAME, path, myInit);
}

export const deleteArea = (areaId: number) => {
  const path = `areas/${areaId}`;
  const myInit = {
    body: {},
    headers: {},
    response: false,
  };

  return API.del(API_NAME, path, myInit);
}

