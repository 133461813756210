import { NumericDictionary, keyBy } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { addResource, deleteResource, getResource, getResources, updateResource } from '../../api/resource';

interface ResourceState {
  data: NumericDictionary<Resource>;
  status: Status;
  createStatus: Status;
  updateStatus: Status;
  error: string | null | undefined;
}

const initialState: ResourceState = {
  data: {},
  status: 'idle',
  createStatus: 'idle',
  updateStatus: 'idle',
  error: null
}

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchResources.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchResources.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.updateStatus = 'idle'
        state.createStatus = 'idle'
        state.data = keyBy(action.payload.resources, 'id')
      })
      .addCase(fetchResources.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchResource.pending, (state) => {
        if (!Object.keys(state.data).length) {
          state.status = 'loading'
        }
      })
      .addCase(fetchResource.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const resource = action.payload;
        state.data = { ...state.data, [resource.id]: resource };
      })
      .addCase(fetchResource.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(triggerCreateResource.pending, (state) => {
        state.createStatus = 'loading'
      })
      .addCase(triggerCreateResource.fulfilled, (state) => {
        state.createStatus = 'succeeded'
      })
      .addCase(triggerCreateResource.rejected, (state) => {
        state.createStatus = 'failed'
      })
      .addCase(triggerUpdateResource.pending, (state) => {
        state.updateStatus = 'loading'
      })
      .addCase(triggerUpdateResource.fulfilled, (state) => {
        state.updateStatus = 'succeeded'
      })
      .addCase(triggerUpdateResource.rejected, (state) => {
        state.updateStatus = 'failed'
      })
  }
})

const selectResourceData = (state: RootState) => state.resource.data
const selectResource = (state: RootState, resourceId: number) => state.resource.data[resourceId]
const selectResourceStatus = (state: RootState) => state.resource.status
const selectResourceCreateStatus = (state: RootState) => state.resource.createStatus
const selectResourceUpdateStatus = (state: RootState) => state.resource.updateStatus
const selectResourceError = (state: RootState) => state.resource.error

export const selectors = {
  selectResource,
  selectResourceData,
  selectResourceStatus,
  selectResourceCreateStatus,
  selectResourceUpdateStatus,
  selectResourceError
}

const fetchResources = createAsyncThunk('getResources', async () => await getResources())
const fetchResource = createAsyncThunk('getResource', async ({ resourceId }: { resourceId: number }) => await getResource(resourceId))
const triggerUpdateResource = createAsyncThunk('updateResource',
  async ({ id, ...rest }: Resource, { dispatch }) => {
    const response = await updateResource(id, rest)
    dispatch(fetchResources())
    return response;
  }
)
const triggerCreateResource = createAsyncThunk('addResource',
  async (resource: Omit<Resource, 'id'>, { dispatch }) => {
    const response = await addResource(resource)
    dispatch(fetchResources())
    return response;
  }
)
const triggerDeleteResource = createAsyncThunk('deleteResource',
  async ({ resourceId }: { resourceId: number }, { dispatch }) => {
    const response = await deleteResource(resourceId)
    dispatch(fetchResources())
    return response;
  }
)

export const actions = {
  fetchResource,
  fetchResources,
  triggerUpdateResource,
  triggerCreateResource,
  triggerDeleteResource
}

export default resourceSlice.reducer