import React from 'react';
import { Button, Card, Flex, Space, Typography } from 'antd';
import styles from './Styles';
import { BookOutlined, CalendarOutlined, EyeOutlined, PlusOutlined, TrophyOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks/typedHooks';
import { selectors as userSelectors } from '../../store/user';
import BasePage from '../../components/BasePage';
import { PATHS, buildPath } from '../paths';

const Home: React.FC = () => {
  const userData = useAppSelector(userSelectors.selectUserData);
  const userStatus = useAppSelector(userSelectors.selectUserStatus);

  const MENU_ITEMS = [
    {
      key: 'sessions',
      icon: <CalendarOutlined style={styles.icon} />,
      label: intl.get('mainMenu.sessions'),
      shortcuts: [
        <Link key={'list'} to={buildPath(PATHS.SESSIONS)} > <Button shape="circle"><EyeOutlined /></Button></Link >,
        <Link key={'new'} to={buildPath(PATHS.NEW_SESSION)}><Button shape="circle"><PlusOutlined /></Button></Link>
      ]
    },
    {
      key: 'customers',
      icon: <UsergroupAddOutlined style={styles.icon} />,
      label: intl.get('mainMenu.customers'),
      shortcuts: [
        <Link key={'list'} to={buildPath(PATHS.CUSTOMERS)} > <Button shape="circle"><EyeOutlined /></Button></Link >,
        <Link key={'new'} to={buildPath(PATHS.NEW_CUSTOMER)}> <Button shape="circle"><PlusOutlined /></Button></Link >
      ]
    },
    {
      key: 'goals',
      icon: <TrophyOutlined style={styles.icon} />,
      label: intl.get('mainMenu.goals'),
      shortcuts: [
        <Link key={'list'} to={buildPath(PATHS.GOALS)}> <Button shape="circle"><EyeOutlined /></Button></Link >,
      ]
    },
    {
      key: 'resources',
      icon: <BookOutlined style={styles.icon} />,
      label: intl.get('mainMenu.resources'),
      shortcuts: [
        <Link key={'list'} to={buildPath(PATHS.RESOURCES)}><Button shape="circle"><EyeOutlined /></Button></Link>,
        <Link key={'new'} to={buildPath(PATHS.NEW_RESOURCE)}><Button shape="circle"><PlusOutlined /></Button></Link>
      ]
    }
  ];

  return (
    <BasePage statuses={[userStatus]}>
      <Space direction="vertical" size="large" style={styles.container}>
        <Typography.Title level={2} style={styles.title}>
          {intl.get('home.welcome', { name: userData?.name })}
        </Typography.Title>
        <Typography.Title level={4} style={styles.subtitle} type="secondary">
          {intl.get('home.subtitle')}
        </Typography.Title>

        <Flex wrap="wrap" justify={'center'} gap="large" style={styles.cardsContainer}>
          {MENU_ITEMS.map((item) => (
            <Card hoverable key={item.key} title={<Space>{item.icon}{item.label}</Space>} style={styles.card}>
              <Flex justify={'center'} align={'center'} gap="small">
                {item.shortcuts ? item.shortcuts : undefined}
              </Flex>
            </Card>
          ))}
        </Flex>
      </Space>
    </BasePage>
  );
}

export default Home;
