import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as resourceActions, selectors as resourceSelectors } from '../../store/resource';
import { actions as goalActions, selectors as goalSelectors } from '../../store/goal';
import Resources from './Resources';

const ResourcesContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const resourceData = useAppSelector(resourceSelectors.selectResourceData);
  const resourceStatus = useAppSelector(resourceSelectors.selectResourceStatus);
  const goalData = useAppSelector(goalSelectors.selectGoalData);
  const goalStatus = useAppSelector(goalSelectors.selectGoalStatus);

  useEffect(() => {
    dispatch(resourceActions.fetchResources());
    dispatch(goalActions.fetchGoals());
  }, []);

  const handleDeleteResource = (resourceId: number) => {
    dispatch(resourceActions.triggerDeleteResource({ resourceId }));
  }

  return (
    <Resources
      resourceData={resourceData}
      resourceStatus={resourceStatus}
      goalData={goalData}
      goalStatus={goalStatus}
      deleteResource={handleDeleteResource}
    />
  );
}

export default ResourcesContainer;
