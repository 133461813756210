import React, { useEffect } from 'react';
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../store/hooks/typedHooks';
import { actions as sessionActions, selectors as sessionSelectors } from '../../store/session';
import SessionDetail from './components/SessionDetail';
import { useParams } from 'react-router-dom';

const UpdateSessionContainer: React.FC = () => {
  const params = useParams();
  const sessionId = params.sessionId ? Number.parseInt(params.sessionId) : 0;

  const dispatch = useAppDispatch();
  const session = useAppSelector(state => sessionSelectors.selectSession(state, sessionId));
  const status = useAppSelector(sessionSelectors.selectSessionStatus);
  const updateStatus = useAppSelector(sessionSelectors.selectSessionUpdateStatus);

  useEffect(() => {
    dispatch(sessionActions.fetchSession({ sessionId }));
  }, []);

  const handleUpdateSession = (updatedSession: Omit<Session, 'id'>) => {
    console.log('handleUpdateSession', { ...updatedSession, id: session.id })
    dispatch(sessionActions.triggerUpdateSession({ ...updatedSession, id: session.id }));
  }

  return (
    <SessionDetail
      mode="update"
      session={{ ...session, date: dayjs(status === 'succeeded' ? session.date : undefined) }}
      status={status}
      submitStatus={updateStatus}
      onSubmit={handleUpdateSession}
    />
  );
}

export default UpdateSessionContainer;
